import React from 'react';

class PlayComponent extends React.Component
{
    render()
    {
        return <div id="blog" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">이슈 확산과 대응에 효과적인</span>
                        <span className="h-2">언론 플레이</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/play_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>이슈 생성 및 확산에 있어 언론 플레이는 필수적입니다.</div>
                        <div>
                            언론이 가지고 있는 공신력을 활용하여 확산뿐만 아니라 대응하는데 용이하기 때문에 브랜드 가치를 실추시키는 불법적인 행위에 대해 대처할 수 있습니다.
                        </div>
                        <div>
                            또한, 언론 플레이에 있어 어떤 방식으로 구성할 것인지에 대한 전문가들의 기획도 함께 진행되는 서비스입니다.
                        </div>
                    </div>

                    <div className="header">
                        <span className="h-1">발생한 문제에 대한 관리</span>
                        <span className="h-2">여론 관리</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/play_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>여론 관리는 언론 플레이와 더불어 형성되는 모든 플랫폼에 대한 관리가 진행되는 서비스입니다.</div>
                        <div>지금 시대에 가장 인기있는 플랫폼 유튜브부터 지금까지 전 세계적으로 활용하는 페이스북까지 다양한 플랫폼에 대한 여론을 관리하고 조성하는 서비스로 이슈 확산과 관리에 효과적입니다.</div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="circle">
                                온라인 플랫폼
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                연령층 별 관리
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                타겟층 별 관리
                            </div>
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            발생된 여론의 파급력을 낮추는 효과
                        </div>
                        <div className="border-box">
                            새로운 여론을 형성하는데 핵심적인 역할
                        </div>
                        <div className="border-box">
                            전문성과 소통을 중심으로 여론 형성
                        </div>
                    </div>
                </div>
            </div>

            <div className="debug-line"/>
            
            <div style={{marginBottom:"2%"}} className="padding">
                <img className="img_block" src="images/play_component/0.jpg" alt="Play"/>
            </div>
        </div>;
    }
}

export default PlayComponent;