import React from 'react';
import "./PeopleOpenerComponent.css";

class PeopleOpenerComponent extends React.Component
{
    render()
    {
        return <div id="people-opener">
            <div className="banner-wrap">
                <img className="banner" src="images/peopleopener_component/banner.jpg" alt="배너"/>
                <div className="banner-content">
                    <h1 className="mb-4">열린사람들</h1>
                    <p>
                        국내최초 사회공헌 전문 언론사
                    </p>
                </div>
            </div>

            <div className="container mb-4">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <div className="main-logo">
                            <img src="images/peopleopener_component/logo_people.png" alt="로고"/>
                            <div className="small-border">열린사람들</div>
                        </div>
                    </div>

                    <div className="col-lg-10 col-12">
                        <div className="header">
                            <span className="h-1">국내최초 사회공헌 언론사</span>
                            <span className="h-2">열린사람들</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="introduce">
                            <div>
                                국내 최초 사회 공헌 언론사 열린사람들은 2017년 개설되어 국내뿐만 아니라 해외 다양한 사회공헌을
                                소식을 보도하고 있는 언론사입니다. 정계/재계/문화/연예/청년/스포츠/일반/오피니언 등 다양한 분야에
                                있어 진행되고 있는 사회 공헌 활동을 전달하고 있습니다.
                            </div>
                            <div>
                                또한, 다양한 제보를 통해 사람들이 잘 모르고 있는 사회 공헌 소식을 전달함으로써 더욱 따뜻한 세상이
                                구성될 수 있도록 현직 기자들이 활동하고 있습니다.
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-4 col-6">
                                    <img className="w-100" src="images/peopleopener_component/1.png" alt="로고"/>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <img className="w-100" src="images/peopleopener_component/2.png" alt="로고"/>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <img className="w-100" src="images/peopleopener_component/3.png" alt="로고"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-2 col-12"/>
                    <div className="col-lg-10 col-12">
                        <div className="header mt-4">
                            <span className="h-1">열린사람들</span>
                            <span className="h-2">포스트</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="post-text">
                            <div>네이버 공식 인증을 통해 네이버 포스트에 다양한 사회 공헌 소식을 전달하고 있습니다.</div>
                            <div>
                                네이버 포스트 중 최초로 사회 공헌을 전문 포스팅을 진행하고 있어, 사회 공헌 분야에서는 네이버 포스트 중
                                독보적으로 높은 조회수와 네이버판 메인에 노출되는 포스트입니다.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-6">
                                <img className="w-100" src="images/peopleopener_component/4.png" alt="로고"/>
                            </div>
                            <div className="col-lg-4 col-6">
                                <img className="w-100" src="images/peopleopener_component/5.png" alt="로고"/>
                            </div>
                            <div className="col-lg-4 col-6">
                                <img className="w-100" src="images/peopleopener_component/10.png" alt="로고"/>
                            </div>
                            <div className="col-6 mt-3">
                                <img className="w-100" src="images/peopleopener_component/6.png" alt="로고"/>
                            </div>
                            <div className="col-6 mt-3">
                                <img className="w-100" src="images/peopleopener_component/7.png" alt="로고"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-12">
                        <img className="sub-img" src="images/peopleopener_component/logo_openpeople.png" alt="로고"/>
                    </div>
                    <div className="col-lg-10 col-12">
                        <div className="header mt-4">
                            <span className="h-1">열린사람들</span>
                            <span className="h-2">유튜브, 네이버TV</span>
                        </div>
                        
                        <hr className="hr-bold"/>

                        <div className="post-text">
                            <div>네이버 TV와 유튜브를 통해 ‘공익적’ 목적을 추구하고 있는 채널입니다.</div>
                            <div>
                                그중 스타들의 도전 스토리를 담은 ‘도전하는 사람이 아름답다 소셜 스타 토크쇼 - 미개인’은 2015년
                                9월부터 시작하여 2020년 현재까지 다양한 소셜 스타들의 도전 이야기를 인터뷰하고 있습니다.
                            </div>
                            <div>
                                현재까지, 말년 병장 최종훈, 엔터스(유정호), 신수지, 김병지, 허경영 등 다양한 인물들이 출연하였으며
                                각 플랫폼에 특성에 맞게 제작하여 유튜브에서는 최고 96만 회의 조회수를 기록하였습니다.
                            </div>
                        </div>

                        <div>
                            <img className="w-100" src="images/peopleopener_component/8.png" alt="로고"/>
                            <img className="w-100" src="images/peopleopener_component/9.png" alt="로고"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default PeopleOpenerComponent;