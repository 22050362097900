import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './App.css';

import Top from "./Components/Top.js";
import Footer from "./Components/Footer.js";

import HomeComponent from "./Components/HomeComponent.js";

import CorpIntroComponent from "./Components/CorpIntroComponent.js";
import CorpInfoComponent from "./Components/CorpInfoComponent.js";
import PolicyComponent from "./Components/PolicyComponent.js";
import OrganizationComponent from "./Components/OrganizationComponent.js";
import HowPRComponent from "./Components/HowPRComponent.js";

// import PeopleYoutubeComponent from "./Components/PeopleYoutubeComponent.js";
import PeopleOpenerComponent from "./Components/PeopleOpenerComponent.js";
import PeosnalComponent from "./Components/PeosnalComponent.js";
import ServiceComponent from "./Components/ServiceComponent.js";
import FreepassionalComponent from "./Components/FreepassionalComponent.js";
import OEDMComponent from "./Components/OEDMComponent.js";
import ContentComponent from "./Components/ContentComponent.js";

import ConfigurationComponent from "./Components/ConfigurationComponent.js";
import PackageComponent from "./Components/PackageComponent.js";
import ReportComponent from "./Components/ReportComponent.js";
import BlogComponent from "./Components/BlogComponent.js";
import SNSComponenet from "./Components/SNSComponenet.js";
import PortalComponent from "./Components/PortalComponent.js";
import MediaComponent from "./Components/MediaComponent.js";
import BrandComponent from "./Components/BrandComponent.js";
import PlayComponent from "./Components/PlayComponent.js";
 
import CampaignComponent from "./Components/CampaignComponent.js";

import PRComponent from "./Components/PRComponent.js";
import QuestionComponent from "./Components/QuestionComponent.js";

import Scroll from "./Components/Scrolling.js";

class App extends React.Component
{
  componentDidUpdate()
  {
    var anim = $(".component_container");
    anim.removeClass("component_container");
    void anim.offset();
    anim.addClass("component_container");
    window.scrollTo(0, 0);
    $(".mobile_close").get(0).click();
  }

  componentDidMount() {
    AOS.init();
  }

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.onUpdate();
    });
  };

  render()
  {
    return <>
      <div className="wrap">
        <Top/>

        <div className="component_container">
          <Switch>
            <Route exact path="/"><HomeComponent/></Route>

            {/* <Route path="/intro"><CorpIntroComponent/></Route> */}
            <Route path="/info"><CorpInfoComponent/></Route>
            <Route path="/policy"><PolicyComponent/></Route>
            <Route path="/organization"><OrganizationComponent/></Route>
            <Route path="/how"><HowPRComponent/></Route>

            {/* <Route path="/youtube"><PeopleYoutubeComponent/></Route> */}
            <Route path="/opener"><PeopleOpenerComponent/></Route>
            <Route path="/peosnal"><PeosnalComponent/></Route>
            <Route path="/service"><ServiceComponent/></Route>
            <Route path="/freepassional"><FreepassionalComponent/></Route>
            <Route path="/oedm"><OEDMComponent/></Route>
            <Route path="/content"><ContentComponent/></Route>

            <Route path="/configuration"><ConfigurationComponent/></Route>
            <Route path="/package"><PackageComponent/></Route>
            <Route path="/report"><ReportComponent/></Route>
            <Route path="/blog"><BlogComponent/></Route>
            <Route path="/sns"><SNSComponenet/></Route>
            <Route path="/portal"><PortalComponent/></Route>
            <Route path="/media"><MediaComponent/></Route>
            <Route path="/brand"><BrandComponent/></Route>
            <Route path="/play"><PlayComponent/></Route>
            
            <Route path="/campaign"><CampaignComponent/></Route>

            <Route path="/pr"><PRComponent/></Route>
            <Route path="/question"><QuestionComponent/></Route>
          </Switch>
        
          <Footer/>
        </div>
      </div>
      <Scroll/>
    </>;
  }
}

export default withRouter(App);