import React from 'react';
import Slider from 'react-slick';
import './CorpInfoComponent.css';

class CorpInfoComponent extends React.Component
{
    render()
    {
        const settings = {
            dot: false,
            arrows: false,
            infinite: true,
            centerMode: true,
            focusOnSelect: true,
            slidesToShow: 2,
            autoplay: true,
            autoplaySpeed: 1500
        };

        return <div id="corp-info">
            <div className="banner-wrap">
                <img className="banner" src="images/corp_info_component/banner.jpg" alt="배너"/>
                <div className="banner-content">
                    <h1 className="mb-4">회사 소개</h1>
                </div>
            </div>

            <div className="container mb-4">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <div className="nudge-logo">
                            <img src="images\corp_info_component/logo_nudge.png" alt="로고"/>
                            <div className="round-border">넛지스토리</div>
                        </div>

                        <div>
                            <img className="line" src="images\corp_info_component/line.png" alt="로고"/>
                        </div>
                    </div>

                    <div className="col-lg-10 col-12">
                        <div className="category-flex">
                            <span className="text-1">PR전문 기업</span>
                            <span className="text-2">넛지스토리(nudge stroy)</span>
                        </div>
                        
                        <hr className="hr-bold"/>
                        
                        <div className="main-border">
                            <ul>
                                <li>넛지스토리는 스토리를 통해 넛지(NUDGE) 효과를 만드는 PR기업입니다.</li>
                                <li>넛지만의 PR을 통해 부드러운 개입을 유도하고 사람들에게 더 좋은 서비스를 전달하고 있습니다.</li>
                                <li>또한, 기업뿐만 아니라 개인에게도 맞춰진 체계적인 PR을 기획/전략을 구성하고 있습니다.</li>
                                <li>현재 넛지스토리에는 열크사, 열린사람들, 개인브랜드 연구소, 프리래셔널 등 계열사가 준비되어 있습니다. </li>
                            </ul>
                        </div>

                        <div>
                            <div className="category-flex">
                                <span className="text-1">넛지스토리</span>
                                <span className="text-2">계열사</span>
                            </div>

                            <hr className="hr-bold"/>

                            {/* <div>
                                <div className="category-row first row">
                                    <div className="col-3">
                                        <img src="images\corp_info_component/logo_yotube.png" alt="로고"/>
                                        <div className="small-border">열크사</div>
                                    </div>
                                    <div className="sub-border col-9">
                                        <div>
                                            <span>열크사</span> 크리에이터 에이전시
                                        </div>

                                        <ul>
                                            <li>열크사는 국내 최초 크리에이터 에이전시로 전문 크리에이터를 양성하는 기관입니다. </li>
                                            <li>미디어 시대에 발맞춰 크리에이터로써 활동할 수 있도록 기술적인 부분뿐만 아니라 ‘크리에이터’에 초점을 맞춘</li>
                                            <li>교육을 진행하고 있습니다. 열크사만의 5가지(분석, 교육, 실습, 실전, 관리) 체계화 시스템을 통해 전 연령에</li>
                                            <li>이르기까지 다수의 크리에이터를 양성하고 있습니다. 현재 미국 abc뉴스, 영국 로이터 통신, mbc, TV조선 등</li>
                                            <li>다양한 매체에 한국 대표 크리에이터 교육기관으로 소개되고 있습니다.  </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            <div>
                                <div className="category-row first row mt-5">
                                    <div className="col-3">
                                        <img src="images\corp_info_component/logo_people.png" alt="로고"/>
                                        <div className="small-border">열린사람들</div>
                                    </div>
                                    <div className="sub-border col-9">
                                        <div>
                                            <span>열린사람들</span> 국내 최초 사회 공헌 언론사
                                        </div>

                                        <ul>
                                            <li>개인브랜드 연구소는 국내 1호 개인브랜드 매니저로 활동하고 있는 국도형 교수가 설립한 연구소로</li>
                                            <li>개인PR을 중심으로 연구하는 기관입니다.</li>
                                            <li>개인이 가지고 있는 고유한 가치를 발굴하여 개인브랜드를 형성하여 개인에 대한 브랜드를 형성하는 것을 목표로</li>
                                            <li>하고 있습니다. 현재, 개인브랜드의 가치는 전 세계적으로 큰 폭으로 늘어나고 있으며 프리랜서, 1인기업 등</li>
                                            <li>개인이 중심이 되는 4차 혁명 시대에 필수적인 요소로 자리 잡고 있습니다.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="category-row row mt-5 mb-5">
                                    <div className="col-3">
                                        <img className="w-100" src="images\corp_info_component/logo_person.png" alt="로고"/>
                                        <div className="small-border">개인브랜드 연구소</div>
                                    </div>
                                    <div className="sub-border col-9">
                                        <div>
                                            <span>개인브랜드 연구소</span> 국내 1호 개인브랜드 연구소
                                        </div>

                                        <ul>
                                            <li>개인브랜드 연구소는 국내 1호 개인브랜드 매니저로 활동하고 있는 국도형 교수가 설립한 연구소로</li>
                                            <li>개인PR을 중심으로 연구하는 기관입니다.</li>
                                            <li>개인이 가지고 있는 고유한 가치를 발굴하여 개인브랜드를 형성하여 개인에 대한 브랜드를 형성하는 것을 목표로</li>
                                            <li>하고 있습니다. 현재, 개인브랜드의 가치는 전세계적으로 큰 폭으로 늘어나고 있으며 프리랜서, 1인기업 등</li>
                                            <li>개인이 중심이 되는 4차 혁명 시대에 필수적인 요소로 자리 잡고 있습니다.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="category-row row mt-5 mb-5">
                                    <div className="col-3">
                                        <img src="images\corp_info_component/logo_free.png" alt="로고"/>
                                        <div className="small-border">프리패셔널</div>
                                    </div>
                                    <div className="sub-border col-9">
                                        <div>
                                            <span>프리패셔널</span> 프리랜서를 전문가로 만드는 전문 교육
                                        </div>

                                        <ul>
                                            <li>프리패셔널은 프리랜서를 전문가로 만드는 교육 과정으로 프리랜서로 활동하고자 하는 이들에게</li>
                                            <li>검증된 전문 교육을 통해 전문성을 부여하고 해당 분야에 전문가로서 실무를 진행할 수 있도록 하는 교육 플랫폼</li>
                                            <li>입니다. 이용자가 원하는 유통, 마케팅, 세무, 법률, 디자인, 패션, 뷰티, 홈페이지, 홈쇼핑 등 다양한 분야</li>
                                            <li>현직 전문가의 실무 교육을 들을 수 있습니다. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav>
                    <div className="nav nav-tabs justify-content-center mt-4" id="nav-info-tab" role="tablist">
                        <a className="nav-link active" id="nav-info-tab" data-toggle="tab" href="#nav-info-1" role="tab" aria-selected="true">모두</a>
                        <a className="nav-link" id="nav-info-tab" data-toggle="tab" href="#nav-info-2" role="tab" aria-selected="false">서비스표 등록증</a>
                        <a className="nav-link" id="nav-info-tab" data-toggle="tab" href="#nav-info-3" role="tab" aria-selected="false">신문사업 등록증</a>
                        <a className="nav-link" id="nav-info-tab" data-toggle="tab" href="#nav-info-4" role="tab" aria-selected="false">프로그램 등록증</a>
                        <a className="nav-link" id="nav-info-tab" data-toggle="tab" href="#nav-info-5" role="tab" aria-selected="false">벤처기업 확인서</a>
                        <a className="nav-link" id="nav-info-tab" data-toggle="tab" href="#nav-info-6" role="tab" aria-selected="false">출원사실 증명원</a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-info-1" role="tabpanel" aria-labelledby="nav-home-tab">
                        <Slider {...settings}>
                            <div>
                                <img className="w-100" src="images/corp_info_component/corp/0.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div>
                                <img className="w-100" src="images/corp_info_component/corp/1.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div>
                                <img className="w-100" src="images/corp_info_component/corp/2.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div>
                                <img className="w-100" src="images/corp_info_component/corp/3.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div>
                                <img className="w-100" src="images/corp_info_component/corp/4.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                        </Slider>
                    </div>
                    <div className="tab-pane fade" id="nav-info-2" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/0.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/1.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-info-3" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-3"/>
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/2.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div className="col-3"/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-info-4" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-3"/>
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/3.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div className="col-3"/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-info-5" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-3"/>
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/4.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div className="col-3"/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-info-6" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-3"/>
                            <div className="col-6">
                                <img className="w-100" src="images/corp_info_component/corp/5.jpg" alt="회사 등록증, 확인서, 증명원"/>
                            </div>
                            <div className="col-3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default CorpInfoComponent;