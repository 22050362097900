import React from 'react';
import "./PackageComponent.css";

class PackageComponent extends React.Component
{
    render()
    {
        return <div id="package" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header mt-4">
                        <span className="h-1">맞춤형 PR서비스 구성</span>
                        <span className="h-2">패키지</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/package_component/1.png" alt="사진"/>

                    <div className="row">
                        <div className="col-lg-2 col-12">
                            <div className="circle">
                                인물 성공형
                            </div>
                        </div>
                        <div className="col-lg-10 col-12">
                            <div className="post">
                                <div>개인에 맞춰진 패키지 구성으로 인물이 원하는 방향성과 목표점에 도달할 수 있는 방법을 제시합니다.</div>
                                <div>
                                    개인에 브랜딩을 위한 PR 구성으로 이뤄져 있어 새로운 분야에 사업을 진행할 때 자신의 브랜드 가치를 형성하고자 하는
                                    사람들에게 가장 적합한 패키지 구성입니다.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>전문가 서류 검토</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>진행 여부 확인</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>1차 미팅 - 개인 과정 설립</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>PR서비스 진행 및 정기 점검</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>위기 관리</span></div>
                    </div>

                    <div className="flex-btn"><button className="btn">상담하기</button></div>

                    <img className="w-100" src="images/package_component/2.png" alt="사진"/>

                    <div className="row">
                    <div className="col-lg-2 col-12">
                            <div className="circle">
                                기업 PR형
                            </div>
                        </div>
                        <div className="col-lg-10 col-12">
                            <div className="post">
                                <div>기업을 중심으로 진행되는 PR패키지로 단순 매출 증진이 아닌 지속적인 매출 증진, 브랜드화를 위한 플랜 기획, 관리 진행되는 과정입니다.</div>
                                <div>기업 위기관리, 기업 브랜드 형성을 통해 해당 기업만의 차별화된 경쟁력을 갖출 수 있는 것이 특징입니다.</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>기업 전문가 서류 검토</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>1차 미팅 기업 맞춤 컨설팅</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>기업 현장 방문</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>PR서비스 진행 및 정기 점검</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>브랜드 및 위기 관리</span></div>
                    </div>

                    <div className="flex-btn"><button className="btn">상담하기</button></div>
                </div>
            </div>

            <div className="debug-line"/>

            <div style={{marginBottom:"2%"}} className="padding">
                <img className="img_block" src="images/package_component/0.jpg" alt="패키지"/>
            </div>
        </div>;
    }
}

export default PackageComponent;