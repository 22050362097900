import React from 'react';
import {List, X} from 'react-bootstrap-icons';
import {NavLink} from 'react-router-dom';
import "./Top.css";

import $ from "jquery";

class Top extends React.Component
{
    componentDidMount()
    {
        $(() =>
        {
            $('.top_wrap,.top_category').mouseover(function()
            {
                $('.top_category').stop(0).slideDown(200);
                $('.top_category').css("display", "block");
                $('.desktop').addClass('hover');
            });
            $('.top_wrap,.top_category').mouseout(function()
            {
                $('.top_category').stop(0).slideUp(200);
                $('.desktop').removeClass('hover');
            });
            
            $('.mobile_close').click(function()
            {
              $('.mobile_menu').animate({right:"-100%"}, 200);
              $('.screen_black').fadeOut(200);
            });
      
            $('.mobile_icon').click(function()
            {
              $('.mobile_menu').animate({right:"0%"}, 200);
              $('.screen_black').fadeIn(200);
            });
      
            $('.mobile_category_1').click(function()
            {
              $('.mobile_tab_1').slideDown(200);
              $('.mobile_tab_2').slideUp(200);
              $('.mobile_tab_3').slideUp(200);
              $('.mobile_tab_4').slideUp(200);
              $('.mobile_tab_5').slideUp(200);
            });
            $('.mobile_category_2').click(function()
            {
              $('.mobile_tab_2').slideDown(200);
              $('.mobile_tab_1').slideUp(200);
              $('.mobile_tab_3').slideUp(200);
              $('.mobile_tab_4').slideUp(200);
              $('.mobile_tab_5').slideUp(200);
            });
            $('.mobile_category_3').click(function()
            {
              $('.mobile_tab_3').slideDown(200);
              $('.mobile_tab_2').slideUp(200);
              $('.mobile_tab_1').slideUp(200);
              $('.mobile_tab_4').slideUp(200);
              $('.mobile_tab_5').slideUp(200);
            });
            $('.mobile_category_4').click(function()
            {
              $('.mobile_tab_4').slideDown(200);
              $('.mobile_tab_2').slideUp(200);
              $('.mobile_tab_3').slideUp(200);
              $('.mobile_tab_1').slideUp(200);
              $('.mobile_tab_5').slideUp(200);
            });
            $('.mobile_category_5').click(function()
            {
              $('.mobile_tab_5').slideDown(200);
              $('.mobile_tab_2').slideUp(200);
              $('.mobile_tab_3').slideUp(200);
              $('.mobile_tab_4').slideUp(200);
              $('.mobile_tab_1').slideUp(200);
            });
        });
    }

    render()
    {
        return (
        <div>
            <div className="desktop">
                <div className="top_wrap">
                    <ul className="top_category_text">
                        <li><NavLink to="/"><img className="main_logo" src="images/top_component/logo.png" alt="로고"/></NavLink></li>
                        <li>회사소개</li>
                        <li>사업영역</li>
                        <li>PR서비스</li>
                        <li>포트폴리오</li>
                        <li>기타 및 문의</li>
                    </ul>

                    <div className="top_category">
                        <ul className="top_menu">
                            <li>
                                <div className="category_outline"/>
                                <ul>
                                    <NavLink to="/info"><li className="top_margin">회사소개</li></NavLink>
                                    <NavLink to="/policy"><li>경영방침</li></NavLink>
                                    <NavLink to="/organization"><li>조직도</li></NavLink>
                                    <NavLink to="/how"><li>PR이란?</li></NavLink>
                                </ul>
                            </li>

                            <li>
                                <div className="category_outline"/>
                                <ul>
                                    {/* <NavLink to="/youtube"><li className="top_margin">열크사</li></NavLink> */}
                                    <NavLink to="/opener"><li className="top_margin">열린사람들</li></NavLink>
                                    <NavLink to="/peosnal"><li>개인브랜드 연구소</li></NavLink>
                                    <NavLink to="/service"><li>PR서비스</li></NavLink>
                                    <NavLink to="/freepassional"><li>프리패셔널</li></NavLink>
                                    <NavLink to="/oedm"><li>OEM/ODM</li></NavLink>
                                    <NavLink to="/content"><li>콘텐츠 제작</li></NavLink>
                                </ul>
                            </li>

                            <li>
                                <div className="category_outline"/>
                                <ul>
                                    <NavLink to="/configuration"><li className="top_margin">PR구성</li></NavLink>
                                    {/* <NavLink to="/package"><li>패키지(Package)</li></NavLink> */}
                                    <NavLink to="/report"><li>언론 플레이</li></NavLink>
                                    {/* <NavLink to="/blog"><li>블로그(Blog)</li></NavLink> */}
                                    {/* <NavLink to="/sns"><li>SNS</li></NavLink> */}
                                    {/* <NavLink to="/portal"><li>포털(Portal)</li></NavLink> */}
                                    <NavLink to="/media"><li>여론형성</li></NavLink>
                                    <NavLink to="/brand"><li>브랜드기획&제작</li></NavLink>
                                    {/* <NavLink to="/play"><li>언론플레이</li></NavLink> */}
                                </ul>
                            </li>
                            <li>
                                <div className="category_outline"/>
                                <ul>
                                    <NavLink to="/campaign"><li className="top_margin">사회공헌</li></NavLink>
                                </ul>
                            </li>
                            <li>
                                <div className="category_outline"/>
                                <ul>
                                    <NavLink to="/pr"><li className="top_margin">PR문의</li></NavLink>
                                    <NavLink to="/question"><li>제휴문의</li></NavLink>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobile_top">
                <ul className="mobile_icon">
                    <List size={"2rem"}/>
                </ul>

                <div className="screen_black" onClick={
                    () => {
                        $(".mobile_close").trigger("click");
                    }
                }/>

                <div className="mobile_menu">
                    <div className="mobile_close"><X size={"2rem"}/></div>
                    <div className="mobile_menu_icon"><p><NavLink to="/"><img src="images/top_component/logo_m.png" alt="탑 이미지"/></NavLink></p></div>
                    <ul>
                        <li className="mobile_hide_show mobile_category_1">회사 소개</li>
                        <li className="mobile_tab_1" id="mobile_hide">
                            <ul>
                                <NavLink to="/info"><li>회사소개</li></NavLink>
                                <NavLink to="/policy"><li>경영방침</li></NavLink>
                                <NavLink to="/organization"><li>조직도</li></NavLink>
                                <NavLink to="/how"><li>PR이란?</li></NavLink>
                            </ul>
                        </li>
                        <li className="mobile_hide_show mobile_category_2">사업영역</li>
                        <li className="mobile_tab_2" id="mobile_hide">
                            <ul>
                                {/* <NavLink to="/youtube"><li>열크사</li></NavLink> */}
                                <NavLink to="/opener"><li>열린사람들</li></NavLink>
                                <NavLink to="/peosnal"><li>개인브랜드 연구소</li></NavLink>
                                <NavLink to="/service"><li>PR서비스</li></NavLink>
                                <NavLink to="/freepassional"><li>프리패셔널</li></NavLink>
                                <NavLink to="/oedm"><li>OEM/ODM</li></NavLink>
                                <NavLink to="/content"><li>콘텐츠 제작</li></NavLink>
                            </ul>
                        </li>
                        <li className="mobile_hide_show mobile_category_3">PR서비스</li>
                        <li className="mobile_tab_3" id="mobile_hide">
                            <ul>
                                <NavLink to="/configuration"><li>PR구성</li></NavLink>
                                {/* <NavLink to="/package"><li>패키지(Package)</li></NavLink> */}
                                <NavLink to="/report"><li>언론 플레이</li></NavLink>
                                {/* <NavLink to="/blog"><li>블로그(Blog)</li></NavLink> */}
                                {/* <NavLink to="/sns"><li>SNS</li></NavLink> */}
                                {/* <NavLink to="/portal"><li>포털(Portal)</li></NavLink> */}
                                <NavLink to="/media"><li>여론형성</li></NavLink>
                                <NavLink to="/brand"><li>브랜드기획&제작</li></NavLink>
                                {/* <NavLink to="/play"><li>언론플레이</li></NavLink> */}
                            </ul>
                        </li>
                        <li className="mobile_hide_show mobile_category_4">포트폴리오</li>
                        <li className="mobile_tab_4" id="mobile_hide">
                            <ul>
                                <NavLink to="/campaign"><li>사회공헌</li></NavLink>
                            </ul>
                        </li>
                        <li className="mobile_hide_show mobile_last mobile_category_5">기타 및 문의</li>
                        <li className="mobile_tab_5" id="mobile_hide">
                            <ul className="last_tab">
                                <NavLink to="/pr"><li>PR문의</li></NavLink>
                                <NavLink to="/question"><li>제휴문의</li></NavLink>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}

export default Top;