import React from 'react';
import './QuestionComponent.css';

import $ from 'jquery';

class QuestionComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            forceRender: 0,
            inputCorpName: "",
            inputHomepage: "",
            inputName: "",
            inputCuri: "",
            inputNumber: "",
            inputMail: "",
            inputDesc: "",
        }
    }

    forceRender = function() 
    {
        var num = this.state.forceRender + 1;
        this.setState({forceRender:num});
    }
    changedinputCorpName = (event) => 
    {
        this.setState({inputCorpName:event.target.value});
    }
    changedinputHomepage = (event) => 
    {
        this.setState({inputHomepage:event.target.value});
    }
    changedinputName = (event) => 
    {
        this.setState({inputName:event.target.value});
    }
    changedinputCuri = (event) => 
    {
        this.setState({inputCuri:event.target.value});
    }
    changedinputNumber = (event) => 
    {
        this.setState({inputNumber:event.target.value});
    }
    changedinputMail = (event) => 
    {
        this.setState({inputMail:event.target.value});
    }
    changedinputDesc = (event) => 
    {
        this.setState({inputDesc:event.target.value});
    }

    sendMessage = function()
    {
        // if ($("#inlineRadio1").is(":checked") === false && $("#inlineRadio2").is(":checked") === false)
        // {
        //     return false;
        // }
        // if ($("#inlineRadio3").is(":checked") === false &&
        // $("#inlineRadio4").is(":checked") === false &&
        // $("#inlineRadio5").is(":checked") === false &&
        // $("#inlineRadio6").is(":checked") === false &&
        // $("#inlineRadio7").is(":checked") === false)
        // {
        //     return false;
        // }
        // if ($("#inlineRadio8").is(":checked") === false &&
        // $("#inlineRadio9").is(":checked") === false &&
        // $("#inlineRadio10").is(":checked") === false &&
        // $("#inlineRadio11").is(":checked") === false &&
        // $("#inlineRadio12").is(":checked") === false &&
        // $("#inlineRadio13").is(":checked") === false)
        // {
        //     return false;
        // }
        if ($("#checkAgree").is(":checked") === false)
        {
            return false;
        }

        var string = [];
        // string.push(String(this.state.inputCorpName).replace(/^\s+|\s+$/g, ''));
        // string.push(String(this.state.inputHomepage).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputName).replace(/^\s+|\s+$/g, ''));
        // string.push(String(this.state.inputCuri).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputNumber).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputMail).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputDesc).replace(/^\s+|\s+$/g, ''));

        for(var i = 0; i < string.length; i ++)
        {
            if (!(string[i].length >= 1))
            {
                return false;
            }
        }
        return true;
    }

    componentDidMount()
    {
        $(".form-check-input").click(function()
        {
            this.forceRender();
        }.bind(this));

        $("#checkAgree").click(function()
        {
            this.forceRender();
        }.bind(this));
    }

    render()
    {
        var sendButton = this.sendMessage() === false ? <div className="que_send disabled">전송</div> : <div><input type="submit" value="전송" id="btn_submit" className="que_send"></input></div>;

        return (
            <div>
                <div className="banner-wrap">
                    <img className="banner" src="images/pr_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">PR문의</h1>
                        <p>
                            넛지스토리는 언제나 고객을 먼저 생각합니다.<br/>
                            고객에게 최상의 서비스를 제공할 수 있도록 항상 최선을 다하겠습니다.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <iframe className="map" title="넛지스토리" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6326.059997804842!2d127.129382!3d37.55435700000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb00bbb1de013%3A0xe0570cab1ec3e452!2z64yA7ZWc66-86rWtIOyEnOyauO2KueuzhOyLnCDqsJXrj5nqtawg7JWU7IKs64-ZIOyYrOumvO2UveuhnCA4MjQ!5e0!3m2!1sko!2sus!4v1603825071531!5m2!1sko!2sus" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"/>
                </div>

                <div className="question_component que_padding">
                <form method="POST" action="https://script.google.com/macros/s/AKfycbyRqHRiTH_98mBWlQ8LHHq8emSFKf1bqbsmP9s0yme-TXjhZTZs/exec">
                    <input className="no-visit" name="문의 구분" value="PR 문의" readOnly/>

                    <div className="form-group row">
                        <label className="col-lg-1 col-form-label">구분</label>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="구분" id="inlineRadio1" value="신규 고객"/>
                            <label className="form-check-label" htmlFor="inlineRadio1">신규 고객</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="구분" id="inlineRadio2" value="연장 고객"/>
                            <label className="form-check-label" htmlFor="inlineRadio2">연장 고객</label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="input1" className="col-lg-1 col-form-label">회사명</label>
                        <div className="col-lg-4">
                            <input name="회사명" type="text" className="form-control" id="input1" placeholder="" onChange=
                            {
                                this.changedinputCorpName
                            }/>
                        </div>
                        <label htmlFor="input2" className="col-lg-1 col-form-label">홈페이지</label>
                        <div className="col-lg-4">
                            <input name="홈페이지" type="text" className="form-control" id="input2" placeholder="www.aaa.com" onChange=
                            {
                                this.changedinputHomepage
                            }/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="input3" className="col-lg-1 col-form-label">* 고객명</label>
                        <div className="col-lg-4">
                            <input name="이름" type="name" className="form-control" id="input3" placeholder="" onChange=
                            {
                                this.changedinputName
                            }/>
                        </div>
                        <label htmlFor="input4" className="small-text col-lg-1 col-form-label">직급/직책</label>
                        <div className="col-lg-4">
                            <input name="직급/직책" type="text" className="form-control" id="input4" placeholder="" onChange=
                            {
                                this.changedinputCuri
                            }/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="input5" className="col-lg-1 col-form-label">* 연락처</label>
                        <div className="col-lg-4">
                            <input name="연락처" type="text" className="form-control" id="input5" placeholder="010-1234-5678" onChange=
                            {
                                this.changedinputNumber
                            }/>
                        </div>
                        <label htmlFor="input6" className="col-lg-1 col-form-label">* 이메일</label>
                        <div className="col-lg-4">
                            <input name="이메일" type="email" className="form-control" id="input6" placeholder="aaa@xxx.com" onChange=
                            {
                                this.changedinputMail
                            }/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-2 col-form-label">알게 된 경로</label>
                        <div className="form-check form-check-inline">
                            <input name="알게된 경로" className="form-check-input" type="checkbox" id="inlineRadio3" value="인터넷 검색"/>
                            <label className="form-check-label" htmlFor="inlineRadio3">인터넷 검색</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="알게된 경로" className="form-check-input" type="checkbox" id="inlineRadio4" value="배너 광고"/>
                            <label className="form-check-label" htmlFor="inlineRadio4">배너 광고</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="알게된 경로" className="form-check-input" type="checkbox" id="inlineRadio5" value="지인소개"/>
                            <label className="form-check-label" htmlFor="inlineRadio5">지인소개</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="알게된 경로" className="form-check-input" type="checkbox" id="inlineRadio6" value="안내 메일"/>
                            <label className="form-check-label" htmlFor="inlineRadio6">안내 메일</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="알게된 경로" className="form-check-input" type="checkbox" id="inlineRadio7" value="엘리베이터 광고"/>
                            <label className="form-check-label" htmlFor="inlineRadio7">엘리베이터 광고</label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="inputEmail2" className="col-lg-2 col-form-label">상담구분</label>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio8" value="국내 언론홍보"/>
                            <label className="form-check-label" htmlFor="inlineRadio8">국내 언론홍보</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio9" value="해외 언론홍보"/>
                            <label className="form-check-label" htmlFor="inlineRadio9">해외 언론홍보</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio10" value="온라인 광고"/>
                            <label className="form-check-label" htmlFor="inlineRadio10">온라인 광고</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio11" value="오프라인 광고"/>
                            <label className="form-check-label" htmlFor="inlineRadio11">오프라인 광고</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio12" value="검색 광고"/>
                            <label className="form-check-label" htmlFor="inlineRadio12">검색 광고</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="상담 종류" className="form-check-input" type="checkbox" id="inlineRadio13" value="바이럴 마케팅"/>
                            <label className="form-check-label" htmlFor="inlineRadio13">바이럴 마케팅</label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="inputTextArea" className="col-lg-2 col-form-label">* 내용</label>
                        <textarea name="내용" id="inputTextArea" className="form-control" onChange=
                        {
                            this.changedinputDesc
                        }></textarea>
                    </div>

                    <div className="consulting_scroller">
                        <strong>개인정보 수집 및 이용</strong>
                        <br/>
                        <br/>
                        1. 개인정보의 수집 및 이용 목적<br/>
                        <br/>
                        1) 회사는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.<br/>
                        - 서비스관련 상담, 불량회원의 부정 이용방지, 분쟁조정을 위한 기록보존 및 서비스 관련 정보 제공(고지사항 전달, 본인의사 확인, 불만처리, 서비스 이용 관련 혜택, 유의사항, 편의사항 등 정보제공), 회원 및 이용에 관한 정보의 분석<br/>
                        2) 회사는 수집된 개인정보를 회원님을 위한 보다 더 유용한 서비스의 개발에 이용합니다. 또한 서비스 일부에 광고를 게재할 경우, 정확한 개인정보를 바탕으로 각 서비스나 메뉴 등에 회원님 각자의 관심사와 일치하는 적절한 광고와 내용들을 전달해 드릴 것입니다. 이때, 광고주는 회원님의 개인정보를 볼 수 없습니다.<br/>
                        <br/>
                        2. 개인정보 수집 범위<br/>
                        <br/>
                        회사는 적법하고 안전한 수단으로 다음과 같이 회원님의 개인정보를 수집하고 있습니다.<br/>
                        - 회사명, 고객명, 직책/직급, 연락처, 이메일, 비밀번호 등 필요하다고 판단되는 정보<br/>
                        <br/>
                        3. 개인정보 보유기간 및 이용기간<br/>
                        <br/>
                        회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br/>
                        1) 회사 내부 방침에 의한 정보보유 사유 <br/>
                        ① 부정이용기록으로 인한 부정이용방지 : 1년<br/>
                        2) 관련법령에 의한 정보보유 사유<br/>
                        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/>
                        ① 웹사이트 방문기록 - 통신비밀보호법 : 3개월<br/>
                        ② 본인확인에 관한 기록 - 정보통신망 이용촉진 및 정보보호 등에 관한 법률 : 6개월<br/>
                        ③ 소비자의 불만 또는 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자 보호에 관한 법률 : 3년<br/>
                        ④ 계약 또는 청약철회 등에 관한 기록 - 전자상거래 등에서의 소비자 보호에 관한 법률 : 5년<br/>
                        ⑤ 대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자 보호에 관한 법률 : 5년<br/>
                        ⑥ 기타 회원의 동의를 득한 경우 : 동의를 득한 기간까지
                    </div>
                    <input type="checkbox" id="checkAgree" name="약관 동의" value="동의함"></input>
                    <label htmlFor="checkAgree">개인정보 수집 및 이용에 동의합니다.</label>

                    {sendButton}
                </form>
            </div>
        </div>
        );
    }
}

export default QuestionComponent;