import React from 'react';
import "./BrandComponent.css";

class BrandComponent extends React.Component
{
    render()
    {
        return <div id="brand" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header mt-4">
                        <span className="h-1">차별화된 브랜드 형성</span>
                        <span className="h-2">브랜드 제작</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/brand_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>제품 혹은 개인에 있어 차별화된 전략을 보여주는 브랜드를 제작할 수 있습니다.</div>
                        <div>브랜드 제작 과정은 브랜드 대상을 파악한 뒤 브랜드 기획으로 진행되고 있습니다.</div>
                        <div>브랜드만이 가지고 있는 아이덴티티와 스토리텔링을 통해 다른 브랜드와 차별화되는 것이 특징입니다.</div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">차별화된 브랜드 형성</span>
                        <span className="h-2">브랜드 기획</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/brand_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>브랜드 제작에 있어 브랜드 기획은 클라이언트가 요구하는 브랜드 형태와 현재 트렌드, 그리고 브랜드 방향성을 중심으로 기획이 진행됩니다.</div>
                        <div>미팅을 통해 앞으로의 브랜드 방향성에 대해 확립하고 실무적인 브랜드 관리 방식에 대해 진행됩니다.</div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>브랜드 대상 검토</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>브랜드와 현재 트렌드 분석</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>브랜드 기획 미팅</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>향후 브랜드 관리 방향 기획</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>브랜드 형성</span></div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">향후 발생하는 문제에 대한 해결</span>
                        <span className="h-2">브랜드리스크 관리</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/brand_component/3.png" alt="사진"/>

                    <div className="post">
                        <div>브랜드 관리에 있어 발생하는 문제점을 해결하고 방지하는 서비스입니다.</div>
                        <div>브랜드 리스크는 사소한 변화에도 발생할 수 있기 때문에 매우 변칙적입니다.</div>
                        <div>또한, 문제 발생에 있어 중요한 해결 방법과 앞으로의 대비책을 구상합니다.</div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            현재 가장 많이 활용하는 콘텐츠로 PR노출 효과 ▲
                        </div>
                        <div className="border-box">
                            단기간 성장 가능성이 가장 높은 콘텐츠
                        </div>
                        <div className="border-box">
                            생동감 있는 콘텐츠로 신뢰성 ▲
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default BrandComponent;