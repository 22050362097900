import React from 'react';
import "./OrganizationComponent.css";

class OrganizationComponent extends React.Component
{
    render()
    {
        return (
            <div id="organization">
                <div className="banner-wrap">
                    <img className="banner" src="images/organization_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">조직도</h1>
                    </div>
                </div>

                <div className="container small">
                    <div className="text-left">
                        <span className="header-1">넛지스토리(Nudge Story)</span>
                        <span className="header-2">조직도</span>
                    </div>

                    <hr className="hr-bold"/>

                    <img className="image" src="images/organization_component/logo.png" alt="로고"/>

                    <div className="flex">
                        <div className="line">
                            <div className="header">
                                PR전략사업부
                                <div className="circle"/>    
                            </div>
                            <ul>
                                <li>PR전략팀</li>
                                <li>미디어기획팀</li>
                                <li>종합브랜딩팀</li>
                            </ul>
                        </div>

                        <div className="line">
                            <div className="header">
                                전략기획본부
                                <div className="circle"/>
                            </div>
                            <ul>
                                <li>전략기획팀</li>
                                <li>미디어운영팀</li>
                                <li>PR실무팀</li>
                                <li>디자인팀</li>
                            </ul>
                        </div>

                        <div className="line">
                            <div className="header">
                                경영관리부
                                <div className="circle"/>    
                            </div>
                            <ul>
                                <li>경영지원팀</li>
                                <li>종합회계팀</li>
                                <li>대외협력팀</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="text-left">
                        <span className="header-1">시설</span>
                        <span className="header-2">내부 전경</span>
                    </div>

                    <hr className="hr-bold mb-2"/>

                    <div className="row mb-4">
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p0.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p1.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p3.png" alt="시설 내부 전경"/>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p4.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p5.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p6.png" alt="시설 내부 전경"/>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p7.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p8.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p10.png" alt="시설 내부 전경"/>
                        </div>
                    </div>
                    <div className="row pb-4 position-relative">
                        <div className="col-4">
                            <img className="card-img-top card-img-bottom w-100" src="images/organization_component/p/p11.png" alt="시설 내부 전경"/>
                        </div>
                        <div className="col-4"/>
                        <div className="col-4"/>
                        <img className="background" src="images/organization_component/city.png" alt="로고"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrganizationComponent;