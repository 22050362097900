import React from 'react';
import "./SNSComponenet.css";

class SNSComponenet extends React.Component
{
    render()
    {
        return <div id="sns" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">높은 확산력과 파급력</span>
                        <span className="h-2">SNS</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="img-small" src="images/sns_component/1.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/sns_component/2.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 플랫폼 중 가장 높은 확산력과 파급력을 가지고 있는 SNS는 현재 페이스북과 인스타그램의 비중이 매우 높습니다.</div>
                        <div>20 대 10명 중 8명은 SNS을 이용하고 있으며 브랜드 페이지를 통해 확산력을 끌어올릴 수 있다는 것이 특징입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">콘텐츠 확산의 핵심</span>
                        <span className="h-2">페이스북</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="w-100" src="images/sns_component/3.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="w-100" src="images/sns_component/4.png" alt="사진"/>
                        </div>
                    </div>


                    <div className="post">
                        <div>
                            페이스북은 콘텐츠 확산에 있어 매우 적합한 플랫폼입니다. 
                        </div>
                        <div>
                            좋아요, 공유를 통해 자신과 친구가 되어있는 이들에게 홍보하는 주체가 될 수 있습니다.
                        </div>
                        <div>
                            그렇기에 단순 1회 좋아요의 효과는 최소 100배 이상의 확산성을 가지고 있다고 볼 수 있습니다. 
                        </div>
                        <div>
                            또한, 페이스북의 맞춤 타겟팅 광고 시스템을 통해 특정 분야에 대상에게 집중적으로 전달할 수 있어 투자 대비 높은 효율을 나타낼 수 있는 가능성이 있다는 것이 특징입니다.
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="flex-box col-6">
                            <div className="circle">
                                브랜드 페이지
                            </div>
                        </div>
                        <div className="flex-box col-6">
                            <div className="circle">
                                페이스북<br/>광고
                            </div>
                        </div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">개인적인 공개 공간</span>
                        <span className="h-2">인스타그램</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/sns_component/5.png" alt="사진"/>

                    <div className="post">
                        <div>인스타그램은 공개적인 공간이지만 개인성이 철저히 보장되는 특성을 가지고 있습니다.</div>
                        <div>자신이 SNS의 중심이 되기 때문에 일상과 매우 밀접한 관계가 있어 현실과 매우 근접한 플랫폼입니다.</div>
                        <div>
                            좋아요를 누른다고해서 확산이 되는 것이 아니지만 자신이 관심 있는 분야에 대해 더보기 기능을 제공하여
                            맞춤 타겟이 가능하다는 것이 특징입니다.
                        </div>
                        <div>또한, 인스타그램에서만 활동하는 셀럽의 개념이 확립되어 있어 친근하게 타겟에 접근할 수 있는 특색이 있습니다.</div>
                    </div>

                    <div className="row mb-4">
                        <div className="flex-box col-4">
                            <div className="circle">
                                인스타 셀럽
                            </div>
                        </div>
                        <div className="flex-box col-4">
                            <div className="circle">
                                인스타 프레스
                            </div>
                        </div>
                        <div className="flex-box col-4">
                            <div className="circle">
                                타겟팅 광고
                            </div>
                        </div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">소통과 확산의 중심 SNS</span>
                    </div>

                    <hr className="hr-bold"/>

                    <img className="w-100 mt-4" src="images/sns_component/6.png" alt="사진"/>

                    <div className="post">
                        <div>SNS는 소통과 확산에 매우 효과적인 플랫폼입니다.</div>
                        <div>과거 개인 SNS에서 활동하는 이들은 지금 시대에 인플루언서라 불리며 연예인 못지않은 인기를 가지고 있습니다.</div>
                        <div>
                            이를 통해 생성되는 브랜드 효과는 다른 SNS로 퍼져나가며 확산의 중심으로 작용할 수 있다는 것이 SNS의 가장 큰 장점이라 볼 수 있습니다.
                            맞춤 타겟이 가능하다는 것이 특징입니다.
                        </div>
                    </div>
                </div>
            </div>

            <div className="debug-line"/>

            <div style={{marginBottom:"2%"}} className="padding">
                <img className="img_block" src="images/sns_component/0.jpg" alt="SNS"/>
            </div>
        </div>;
    }
}

export default SNSComponenet;