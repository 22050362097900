import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';
import './index.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReactGA = require('react-ga');
ReactGA.initialize('G-JX9BEDFH6W');

function logPageView() {
  ReactGA.set({page: window.location.pathname});
  ReactGA.pageview(window.location.pathname);
}
logPageView();

ReactDOM.render(<BrowserRouter><App onUpdate={logPageView}/></BrowserRouter>, document.getElementById('root'));
serviceWorker.unregister();