import React from 'react';
import "./CampaignComponent.css";

class CampaignComponent extends React.Component
{
    render()
    {
        return (
            <div id="CampaignComponent">
                <div className="banner-wrap">
                    <img className="banner" src="images/campaign_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">사회공헌</h1>
                        <p>
                            자발적 사회재분배를 통한 행복한 세상<br/>
                            "한국형 노블레스 오블리주"
                        </p>
                    </div>
                </div>
                <div className="container">
                <div id="list-item-1" className="card">
                    <div className="card-header">황인성의 구구콘</div>
                        <img src="images/campaign_component/0.png" className="lazy card-img-top" alt="황인성의 구구콘"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>입에서 입으로 전해지는 국내 최초 언론 진로캠페인</li>
                                <li>연합뉴스TV 메인 앵커 황인성과 현직 언론인이 함께하는 언론 교류의 장</li>
                                <li>현장에서 알려주는 실무 경험</li>
                                <li>지속 가능 릴레이 콘서트</li>
                                <li>언론 꿈나무의 올바른 길 제시</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-2" className="card">
                        <div className="card-header">심청이 캠페인</div>
                        <img src="images/campaign_component/1.png" className="lazy card-img-top" alt="심청이"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>마음의 귀를 통해 듣다</li>
                                <li>복지관 및 요양원 어르신 방문 헬스케어 캠페인</li>
                                <li>헬스케어 전문가 초빙</li>
                                <li>지속관리형 건강관리 캠페인</li>
                                <li>기성세대와 청년세대 간 소통의 장</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-3" className="card">
                        <div className="card-header">청년, 대장에게 꿈을 묻다</div>
                        <img src="images/campaign_component/2.png" className="lazy card-img-top" alt="청년, 대장에게 꿈을 묻다"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>청년 투표 선정, 전국 대장 릴레이 인터뷰 캠페인</li>
                                <li>전국의 대장들이 직접 알려주는 청년들의 궁금증</li>
                                <li>대장과 청년의 교류의 장</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-4" className="card">
                        <div className="card-header">다문화가정 지원 캠페인</div>
                        <img src="images/campaign_component/3.png" className="lazy card-img-top" alt="다문화가정과 함께하는 뮤직콘서트"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>차별 받지 않는 평등한 문화 추구</li>
                                <li>대한민국 속에서 하나되는 지구촌</li>
                                <li>국 내외 문화의 교류 및 융합의 장</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-5" className="card">
                        <div className="card-header">반려동물 보호 캠페인</div>
                        <img src="images/campaign_component/4.png" className="lazy card-img-top" alt="청년과 청소년의 교류의 장 교육봉사단"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>함께하는 삶 반려동물</li>
                                <li>반려동물 보호를 위한 굿즈 및 크라우드 펀딩</li>
                                <li>유기동물 지원</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-6" className="card">
                        <div className="card-header">빛솔콘서트, 빛날 우리들의 솔직한 콘서트</div>
                        <img src="images/campaign_component/5.png" className="lazy card-img-top" alt="빛솔 콘서트, 빛날 우리들의 솔직한 콘서트"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>청년과 청소년 교류의 장</li>
                                <li>가장 가까운 인생의 선배가 전해주는 대학 및 사회생활</li>
                                <li>진학에서 취업까지 함께 공유하는 세대</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-7" className="card">
                        <div className="card-header">챌린저바이블</div>
                        <img src="images/campaign_component/6.png" className="lazy card-img-top" alt="챌린저 바이블"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>대한민국의 오피니언들의 도전스토리를 전세계로</li>
                                <li>세계 최초 인터뷰를 통한 도서출판 및 인생 지침서</li>
                                <li>선한 영향력의 교과서</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-8" className="card">
                        <div className="card-header">너의 비상을 응원한다 비기너스 캠페인</div>
                        <img src="images/campaign_component/7.png" className="lazy card-img-top" alt="너의 비상을 응원한다 비기너스 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>비상을 기다리는 너를 위한 스토리</li>
                                <li>청년들의 비상을 직접 찾아가는 희망을 현실로 만드는 프로젝트</li>
                                <li>비상 경험 전수 캠페인</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-9" className="card">
                        <div className="card-header">전통개그의 부활 통조림 캠페인</div>
                        <img src="images/campaign_component/8.png" className="lazy card-img-top" alt="전통개그의 부활 통조림 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>숨겨진 개그맨 부활 프로젝트</li>
                                <li>개그맨들의 새로운 무대 제공 캠페인</li>
                                <li>개그맨들의 꿈을 현실로 재가공</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-10" className="card">
                        <div className="card-header">송경택의 지식인 전세대 궁금증 타파 캠페인</div>
                        <img src="images/campaign_component/9.png" className="lazy card-img-top" alt="전세대 궁금증 타파 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>쇼트트랙 국가대표 출신 현 국가대표 감독의 전문가 교류 캠페인</li>
                                <li>전국 전문가 초청, 취 창업 궁금증 해결</li>
                                <li>전세대의 마중물 역할</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-11" className="card">
                        <div className="card-header">사랑의 도시락 나눔 캠페인</div>
                        <img src="images/campaign_component/10.png" className="lazy card-img-top" alt="노숙자 도시락 나눔 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>노숙자 도시락 나눔 캠페인</li>
                                <li>후원을 통한 정기 도시락 제공</li>
                                <li>정기 지원을 통한 인권회복의 도약점 마련</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-12" className="card">
                        <div className="card-header">연탄봉사 캠페인</div>
                        <img src="images/campaign_component/11.png" className="lazy card-img-top" alt="연탄봉사 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>숨겨진 이웃들의 따뜻한 겨울 공유 캠페인</li>
                                <li>정기 프로젝트, 공인과 함께하는 희망 나눔 캠페인</li>
                                <li>전세대 나눔 교류의 장</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-13" className="card">
                        <div className="card-header">사회공헌 크리에티브 어워드</div>
                        <img src="images/campaign_component/12.png" className="lazy card-img-top" alt="사회공헌 크리에티브 어워드"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>디자이너 꿈나무들의 무대</li>
                                <li>대한민국만의 크리에이티브 인증 캠페인</li>
                                <li>디자이너 꿈나무들의 무료 전시회 지원</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-14" className="card">
                        <div className="card-header">무연고자 지원 캠페인</div>
                        <img src="images/campaign_component/13.png" className="lazy card-img-top" alt="무연고자 지원 캠페인"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>전국 무연고자 지원 캠페인</li>
                                <li>국가가 아닌 민간 차원에서 진행되는 자발적인 지원</li>
                                <li>함께 나누고 삶을 함께 나누며 대한민국의 새로운 장례문화 전달</li>
                            </ul>
                        </div>
                    </div>
                    <div id="list-item-15" className="card">
                        <div className="card-header">미개인 캠페인</div>
                        <img src="images/campaign_component/14.png" className="lazy card-img-top" alt="미개인 전국대학 진로콘서트"/>
                        <div className="card-body">
                            <ul className="card-text">
                                <li>아름다운 사람들이 여는 대한민국의 미래</li>
                                <li>인생 선배가 전하는 실무 경험과 인생 경험</li>
                                <li>내리 나눔으로 자리잡는 사회공헌 문화</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignComponent;