import React from 'react';
import "./OEDMComponent.css";

class OEDMComponent extends React.Component
{
    render()
    {
        return (
            <div id="oedm">
                <div className="banner-wrap">
                    <img className="banner" src="images/oedm_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">OEM / ODM</h1>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-12">
                            <div className="main-logo">
                                <img src="images/oedm_component/logo.png" alt="로고"/>
                                <div className="small-border">OEM/ODM 대행</div>
                            </div>
                        </div>

                        <div className="col-lg-10 col-12">
                            <div className="header">
                                <span className="h-1">브랜드를 활용한 유통 관리</span>
                                <span className="h-2">OEM/ODM 대행</span>
                            </div>

                            <hr className="hr-bold"/>

                            <div className="introduce">
                                <div>
                                    <div>oem/odm은 자체 생산에 대한 리스크를 줄일 수 있어 많은 관심을 두고 있는 부분 중 하나입니다.</div>
                                    <div>oem은 주문자 상표 부착 생산으로 한 분야에 대해 집중하기 때문에 업무의 효율성이 높다는 장점이 있습니다.</div>
                                    <div>또한, 이에 해당되는 리스크가 줄어들게 되어 처음 브랜드 제품을 생산하고자 하는 분들의 수요가 높습니다.</div>
                                    <div>odm은 제조업자 개발 생산 방식으로 설계, 계발 능력을 갖춘 제조 업체가 유통망과 브랜드 효과를 활용한 방식 형태입니다.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="container-small">
                        <div className="header mt-4">
                            <span className="h-1">체계적인 진행 시스템</span>
                            <span className="h-2">ODM/OEM 프로세스</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <div className="mb-4">
                            <div className="border-box"><span>1. OEM/ODM 요청</span></div>
                            <img src="images/oedm_component/arrow.png" alt="사진"/>
                            <div className="border-box"><span>2. 요청 양식 제공</span></div>
                            <img src="images/oedm_component/arrow.png" alt="사진"/>
                            <div className="border-box"><span>3. 담당자 대면 미팅</span></div>
                            <img src="images/oedm_component/arrow.png" alt="사진"/>
                            <div className="border-box"><span>4. 제조업체 대면 미팅</span></div>
                            <img src="images/oedm_component/arrow.png" alt="사진"/>
                            <div className="border-box"><span>5. 제조업체 대면 미팅</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OEDMComponent;