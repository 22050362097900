import React from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";

import "./HomeComponent.css";

class HomeComponent extends React.Component {
  componentDidMount() {
    $('.carousel').carousel({
      interval: 4000,
      pause: false
    });
  }

  render() {
    return <div>
      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
        <div className="carousel-inner">
          <NavLink to="/info">
            <div className="carousel-item active">
              <img className="slick_home_image" src="images/main_component/0.jpg" alt="메인 배너 이미지"/>
            </div>
            <div className="carousel-item">
              <img className="slick_home_image" src="images/main_component/1.jpg" alt="메인 배너 이미지"/>
            </div>
            <div className="carousel-item">
              <img className="slick_home_image" src="images/main_component/2.jpg" alt="메인 배너 이미지"/>
            </div>
          </NavLink>
        </div>
        <div className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">이전</span>
        </div>
        <div className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">다음</span>
        </div>
      </div>


        <NavLink to="/configuration">
          <div data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
            <img className="w-100" src="images/main_component/4.jpg" alt="메인 배너 이미지"/>
          </div>
        </NavLink>
        <div className="home-line"
        data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
          <img className="w-100" src="images/main_component/3.jpg" alt="메인 배너 이미지"/>
          <NavLink to="/service"><div className="button-1"/></NavLink>
          {/* <NavLink to="/youtube"><div className="button-2"/></NavLink> */}
          <NavLink to="/peosnal"><div className="button-3"/></NavLink>
        </div>
        <NavLink to="/pr">
          <div data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
            <img className="w-100" src="images/main_component/5.jpg" alt="메인 배너 이미지"/>
          </div>
        </NavLink>
      </div>

  }
}

export default HomeComponent;