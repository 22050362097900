import React from 'react';
import './CorpIntroComponent.css';

class CorpIntroComponent extends React.Component
{
    render()
    {
        return <div id="corp-intro">
            <div className="banner-wrap">
                <img className="banner" src="images/corp_intro_component/banner.jpg" alt="배너"/>
                <div className="banner-content">
                    <h2 className="mb-4">대표 소개</h2>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="images col-lg-3 col-12">
                        <img className="one" src="images/corp_intro_component/logo.png" alt="로고"/>
                        <img className="two" src="images/corp_intro_component/profile.png" alt="프로필"/>
                    </div>
                    <div className="col-lg-9 col-12 template">
                        <div>
                            <div className="category">
                                <div>
                                    <span className="one">대표 이사</span>
                                    <span className="two">국도형</span>
                                    <span className="three">Kuk DO-HYUNG</span>
                                </div>
                                <div className="four">국내 1호 개인브랜드 매니저</div>
                            </div>
                            <hr className="hr-bold"/>
                            <div className="one-div">
                                <div>안녕하세요 넛지스토리 대표 이사 국도형입니다.</div>
                                <div>
                                    넛지스토리는 개인&기업 PR을 전문적으로 진행하고 있는 기업입니다. 
                                    넛지(NUDGE) 효과에 대해 알고 계신 분들이라면 조그만한 행동으로 얼마나 큰 가치를 만들어낼 수 있는지 알고 계실겁니다. 
                                    현대 흐름에 맞는
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="sub-category mt-4">약력</div>
                            <hr className="hr"/>
                            <ul>
                                <li>국내 1호 개인브랜드 매니저</li>
                                <li>YBS뉴스통신 논설위원</li>
                                <li>4차 산업혁명 미래직업 창작사례 및 창안 경진대회 운영위원회 위원</li>
                                <li>제 29화 월드미스유니버시티세계대회 2018유치위원회 광고기획위원</li>
                                <li>한국열린사이버대학교 뷰티건강디자인학과 특임교수</li>
                                <li>한국저널리스트대학 마케팅기획 전문교육위원</li>
                                <li>훈사이버평생교육원 마케팅기획 전임교수</li>
                                <li>서울특별시 도봉구 지속가능발전위원회 위원</li>
                                <li>서울특별시 도봉구 청년대책위원회 위원</li>
                                <li>서울특별시 강동구 청년정책위원회 위원</li>
                                <li>재단법인 도봉문화재단 이사</li>
                                <li>주간인물 서울취재부 차장</li>
                            </ul>
                        </div>

                        <div>
                            <div className="sub-category mt-4">수상내역</div>
                            <hr className="hr"/>
                            <ul>
                                <li>대한민국 문화발전인재 대상</li>
                                <li>대한민국 블록체인 산업대상 문화부문</li>
                                <li>주한온두라스대사 표창</li>
                                <li>서울특별시장 표창</li>
                                <li>서울특별시도시철도공사장 표창</li>
                                <li>서울중랑경찰서장 표창</li>
                                <li>도전한국인 국회 행정안전위원회 위원장 표창</li>
                                <li>한국시민자원봉사회 중앙회장 표창</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <img className="bottom img_block" src="images/corp_intro_component/bottom.png" alt="이미지 배너"/>
        </div>
    }
}

export default CorpIntroComponent;