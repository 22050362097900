import React from 'react';
import './PeosnalComponent.css';

import Slider from "react-slick";

class PeosnalComponent extends React.Component
{
    countImageImage = function(array)
    {
        var answer = [];
        for(var i = 0; i < array; i ++)
        {
            var src = `images/peosnal_component/1/${i}.jpg`;
            answer.push(
            <div key={i}>
                <img className="slider_image img_block" src={src} alt="개인 브랜드 이미지"/>
            </div>
            );
        }
        return (answer);
    }

    countGalleryImage = function(array)
    {
        var answer = [];
        for(var i = 0; i < array; i ++)
        {
            var src = `images/peosnal_component/3/${i}.jpg`;
            answer.push(
            <div key={i}>
                <img className="slider_image img_block" src={src} alt="개인 브랜드 갤러리"/>
            </div>
            );
        }
        return (answer);
    }
    countPoImage = function(array)
    {
        var answer = [];
        for(var i = 0; i < array; i ++)
        {
            var src = `images/peosnal_component/4/${i}.jpg`;
            answer.push(
            <div key={i}>
                <img className="img_block img-port" src={src} alt="개인 브랜드 포트폴리오"/>
            </div>
            );
        }
        return (answer);
    }

    render()
    {
        const settings = 
        {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
        };
        const settings_2 = 
        {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        // var slide_1 = this.countImageImage(3); //  개인브랜드 방향성 이미지 수
        var slide_2 = this.countGalleryImage(6); // 개인브랜드 개인의 가치 이미지 수
        var slide_3 = this.countPoImage(7); // 개인브랜드 포트폴리오 수

        return <div id="personal">
            <div className="banner-wrap">
                <img className="banner" src="images/peosnal_component/banner.jpg" alt="배너"/>
                <div className="banner-content">
                    <h1 className="mb-4">개인브랜드</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <div className="main-logo">
                            <img className="brand-logo w-100" src="images/peosnal_component/logo_brand.png" alt="로고"/>
                            <div className="small-border">개인브랜드 연구소</div>
                        </div>
                    </div>

                    <div className="col-lg-10 col-12">
                        <div className="header">
                            <span className="h-1">국내 1호 개인브랜드 매니저</span>
                            <span className="h-2">개인브랜드 연구소</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="introduce">
                            <div>
                                개인브랜드 연구소는 국내 1호 개인브랜드 매니저로 활동하고 있는 국도형 교수가 개설한 연구소로 개인PR을 중심으로 운영되고 있습니다. 
                            </div>
                            <div>
                                또한, 개인브랜드의 중요성은 전 세계적으로 높아지고 있으며 프리랜서 1인 기업 등 개인이 중심이 되는 4차 혁명 시대에 필수적인 요소로 자리 잡고 있습니다. 
                            </div>
                            <div>
                                개인이 가지고 있는 고유한 가치를 발굴하여 개인브랜드를 형성하는 데 중점을 두고 있습니다. 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-small">
                    <div className="content-margin">
                        <div className="header mt-4">
                            <span className="h-1">개인 브랜드의 방향성을 확립</span>
                            <span className="h-2">개인브랜드 컨설팅</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="post-text margin">
                            <div>
                                <div>
                                    개인브랜드의 방향성을 확립하는 개인브랜드 컨설팅은 개인이 가지고 있는 브랜드의 방향성을 기획하는 컨설팅입니다.
                                </div>
                                <div>
                                    자신이 가고자 하는 목표에 대한 효율적인 방향성을 제시하고 이를 어떤 방식으로 나아가야 하는지
                                    실무적인 방법에 대해 안내하고 있습니다.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12 mb-3">
                            <img className="w-100" src="images/peosnal_component/1/0.jpg" alt="로고"/>
                        </div>
                        <div className="col-lg-4 col-12 mb-3">
                            <img className="w-100" src="images/peosnal_component/1/1.jpg" alt="로고"/>
                        </div>
                        <div className="col-lg-4 col-12">
                            <img className="w-100" src="images/peosnal_component/1/2.jpg" alt="로고"/>
                        </div>
                    </div>

                    <div className="content-margin">
                        <div className="header mt-4">
                            <span className="h-1">개인브랜드 형성에 대한 과정 점검</span>
                            <span className="h-2">개인브랜드 자문</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="post-text margin">
                            <div>
                                <div>
                                    개인브랜드 자문은 개인브랜드 형성에 대한 과정을 매월 정기 미팅을 통해 점검하고 방향성에 대한
                                    점검과 앞으로 진행할 부분에 대한 확립을 만드는 과정입니다.
                                </div>
                                <div>
                                    또한 상시 자문을 통해 위기관리, 문제 해결, 진행 보완 등 월 단위 개인 브랜드 솔류션이 진행됩니다.
                                </div>
                                <div>
                                    법률, 세무, 유통, 마케팅 등 전문적인 분야에 대해 실무 전문가의 자문이 가능하며 1년 기준으로 진행됩니다.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <img className="w-100" src="images/peosnal_component/2/0.jpg" alt="로고"/>
                    </div>

                    <div className="content-margin">
                        <div className="header mt-4">
                            <span className="h-1">4차 혁명시대, 더욱 중요해진 개인의 가치</span>
                            <span className="h-2">개인브랜드 강연</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="post-text margin">
                            <div>
                                개인브랜드 강연은 각 기관 지자체, 학교, 대형 학원, 기업, 등 다양한 분야에 있어 진행되고 있으며
                                10대부터 60대까지 연령 구분 없이 다양한 연령층에게 필요한 내용을 담고 있습니다.
                            </div>
                            <div>
                                평생직장의 개념이 사라지고 있는 시대에서 제2의 인생을 준비하는 사람들과 브랜드 가치를 형성
                                하고자 하는 이들에게 높은 수요를 가지고 있는 강연입니다.
                            </div>
                        </div>
                    </div>

                    <div>
                        <Slider {...settings}>
                            {slide_2}
                        </Slider>
                    </div>

                    <div className="content-margin">
                        <div className="header mt-4">
                            <span className="h-1">개인브랜딩 후 변화</span>
                            <span className="h-2">개인브랜드 포트폴리오</span>
                        </div>
                        <hr className="hr-bold"/>
                    </div>


                    <div>
                        <Slider {...settings_2}>
                            {slide_3}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default PeosnalComponent;