import React from 'react';
import {NavLink} from 'react-router-dom';
import "./ConfigurationComponent.css";
import "./PackageComponent.css";

class ConfigurationComponent extends React.Component
{
    render()
    {
        return <div id="service" className="top-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <div className="main-logo">
                            <img src="images/service_component/logo_pr.png" alt="로고"/>
                            <div className="small-border">PR서비스</div>
                        </div>
                    </div>

                    <div className="col-lg-10 col-12">
                        <div className="header">
                            <span className="h-1">기업을 위한 효과적인 홍보 커뮤니케이션</span>
                            <span className="h-2">PR서비스</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="introduce">
                            <div>PR서비스는 넛지스토리의 핵심으로 기업, 정부, 단체. 개인 등 다분야에 접목되어 있는 서비스입니다.</div>
                            <div>
                                현재, 언론 보도, 블로그, SNS, 커뮤니티, 포털, 방송 등 다양한 플랫폼을 활용하여 RP을 진행하고 있으며
                                특정 타겟 혹은 일반인들에게 쉽게 접목할 수 있는 온라인, 오프라인PR 솔루션을 제공하고 있습니다.
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container-small">
                    <div className="header mt-4">
                        <span className="h-1">신뢰성 있는 콘텐츠</span>
                        <span className="h-2">언론보도</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/service_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>언론 보도는 신뢰성이 높은 콘텐츠로 해당 내용에 대한 공신력을 부여합니다.</div>
                        <div>
                            공신력 높은 언론사 및 중심 포털 사이트 이슈에 적합한 보도자료 기획/송출을 통해
                            PR의 최적화된 콘텐츠를 구성합니다.
                        </div>
                    </div>

                    <div className="header">
                        <span className="h-1">콘텐츠의 중심</span>
                        <span className="h-2">블로그</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/service_component/3.png" alt="사진"/>

                    <div className="post">
                        <div>콘텐츠의 중심인 블로그는 이슈 확산, 키워드, 노출 등 PR에 있어 대중들이 가장 친근하게 접할 수 있는 콘텐츠입니다.</div>
                        <div>브랜드 블로그, 최적화 블로그, 블로그 프레스를 통해 이슈가 안착할 수 있도록 콘텐츠 기획/스토리 라인을 타겟 선정 구성하고 있습니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">높은 확산력과 파급력</span>
                        <span className="h-2">SNS</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/4.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/5.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 플랫폼 중 가장 높은 확산력과 파급력을 가지고 있는 SNS는 현재 페이스북과 인스타그램의 비중이 매우 높습니다.</div>
                        <div>20대 10명중 8명은 SNS을 이용하고 있으며 브랜드 페이지를 통해 확산력을 끌어올릴 수 있다는 것이 특징입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">모든 정보를 연결하는 통로</span>
                        <span className="h-2">포털</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-12">
                            <img className="img-width" src="images/service_component/6.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/7.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/8.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 사용빈도가 가장 높은 플랫폼중 하나로 네이버, 구글, 다음을 꼽을 수 있습니다.</div>
                        <div>해당 포털은 이슈의 검색에 특화되어 있어 기업 PR진행 시 필수적으로 활용해야 하는 플랫폼입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">소비성이 높은 콘텐츠의 중심</span>
                        <span className="h-2">미디어</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/9.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/10.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        미디어는 단순한 영상이 아닌 하나의 영상을 통해 전국적인 이슈를 발생시키는 21세기 필수 콘텐츠입니다.
                        자유롭게 업로드할 수 있지만 자신의 채널을 시청하는 구독자에 따라 확산력이 천차만별로 달라지기 때문에
                        해당 영상을 촬영하는 크리에이터의 영향력도 높습니다.
                        현재는 개인 방송의 틀을 벗어나 정규 방송에서도 개인 방송의 트렌드를 따라가고 있으며 젊은 세대뿐만 아니라 50대 이상 시청장의 비율 또한 매우 높은 전 국민적 콘텐츠입니다.
                    </div>
                </div>
            </div>

            <div id="package" className="container">
                <div className="container-small">
                    <div className="header mt-4">
                        <span className="h-1">맞춤형 PR서비스 구성</span>
                        <span className="h-2">패키지</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/package_component/1.png" alt="사진"/>

                    <div className="row">
                        <div className="col-lg-2 col-12">
                            <div className="circle">
                                인물 성공형
                            </div>
                        </div>
                        <div className="col-lg-10 col-12">
                            <div className="post">
                                <div>개인에 맞춰진 패키지 구성으로 인물이 원하는 방향성과 목표점에 도달할 수 있는 방법을 제시합니다.</div>
                                <div>
                                    개인에 브랜딩을 위한 PR 구성으로 이뤄져 있어 새로운 분야에 사업을 진행할 때 자신의 브랜드 가치를 형성하고자 하는
                                    사람들에게 가장 적합한 패키지 구성입니다.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>전문가 서류 검토</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>진행 여부 확인</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>1차 미팅 - 개인 과정 설립</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>PR서비스 진행 및 정기 점검</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>위기 관리</span></div>
                    </div>

                    <NavLink to="/pr"><div className="flex-btn"><button className="btn">상담하기</button></div></NavLink>

                    <img className="w-100" src="images/package_component/2.png" alt="사진"/>

                    <div className="row">
                    <div className="col-lg-2 col-12">
                            <div className="circle">
                                기업 PR형
                            </div>
                        </div>
                        <div className="col-lg-10 col-12">
                            <div className="post">
                                <div>기업을 중심으로 진행되는 PR패키지로 단순 매출 증진이 아닌 지속적인 매출 증진, 브랜드화를 위한 플랜 기획, 관리 진행되는 과정입니다.</div>
                                <div>기업 위기관리, 기업 브랜드 형성을 통해 해당 기업만의 차별화된 경쟁력을 갖출 수 있는 것이 특징입니다.</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>기업 전문가 서류 검토</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>1차 미팅 기업 맞춤 컨설팅</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>기업 현장 방문</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>PR서비스 진행 및 정기 점검</span></div>
                        <img src="images/package_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>브랜드 및 위기 관리</span></div>
                    </div>

                    <NavLink to="/pr"><div className="flex-btn"><button className="btn">상담하기</button></div></NavLink>
                </div>
            </div>
        </div>;
    }
}

export default ConfigurationComponent;