import React from 'react';
import "./ReportComponent.css";

import PressComponent from "./PressComponent.js";

class ReportComponent extends React.Component
{
    render()
    {
        return <div id="report" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">신뢰성있는 콘텐츠</span>
                        <span className="h-2">언론 보도</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>언론 보도는 일반인들이 가장 신뢰하는 콘텐츠로 해당 내용에 대한 공신력을 부여하고 있습니다.</div>
                        <div>공신력 높은 언론사 및 중심 포털 사이트 이슈에 적합한 보도자료 기획/송출을 통해 PR의 최적화된 콘텐츠를 구성하고 있습니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">노출을 높이는</span>
                        <span className="h-2">대표 포털 노출</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>국내 최대 포털 및 D사 등 다수 포털에 제휴되어 있는 언론 보도를 통해 공신력을 부여하는 역할을 합니다.</div>
                        <div>언론사가 가지고 있는 신뢰를 바탕으로 PR 효율성을 높이고 키워드 노출을 통한 확산성을 부여합니다.</div>
                        <div>기사에 들어가는 가독성을 높이는 내용 구성과 카피라이팅을 통한 제목 구성 서비스를 제공합니다</div>
                    </div>

                    <div className="header">
                        <span className="h-1">광고가 아닌 정보</span>
                        <span className="h-2">언론 보도</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/3.jpg" alt="사진"/>

                    <div className="post">
                        <div>언론보도는 잡지, TV광고, 홈쇼핑 등 다수의 매체들과 달리 광고로 인식되는 것이 아닌 정보로 인식되고 있습니다.</div>
                        <div>그렇기 때문에 정보를 전달하는데 있어 신뢰성이 확보됩니다.</div>
                        <div>다수의 매체를 통한 PR과 더불어 핵심적인 이슈를 확정짓는데 언론보도가 큰 비중을 차지하고 있습니다.</div>
                    </div>

                    <div className="border-box">
                        광고가 아닌 정보로 신뢰성을 전달하는 핵심 콘텐츠
                    </div>
                    <div className="border-box">
                        브랜드에 대한 공신력 확보에 용이
                    </div>
                </div>
            </div>

            <div id="blog" className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">이슈 확산과 대응에 효과적인</span>
                        <span className="h-2">언론 플레이</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/play_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>이슈 생성 및 확산에 있어 언론 플레이는 필수적입니다.</div>
                        <div>
                            언론이 가지고 있는 공신력을 활용하여 확산뿐만 아니라 대응하는데 용이하기 때문에 브랜드 가치를 실추시키는 불법적인 행위에 대해 대처할 수 있습니다.
                        </div>
                        <div>
                            또한, 언론 플레이에 있어 어떤 방식으로 구성할 것인지에 대한 전문가들의 기획도 함께 진행되는 서비스입니다.
                        </div>
                    </div>

                    <div className="header">
                        <span className="h-1">발생한 문제에 대한 관리</span>
                        <span className="h-2">여론 관리</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/play_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>여론 관리는 언론 플레이와 더불어 형성되는 모든 플랫폼에 대한 관리가 진행되는 서비스입니다.</div>
                        <div>지금 시대에 가장 인기있는 플랫폼 유튜브부터 지금까지 전 세계적으로 활용하는 페이스북까지 다양한 플랫폼에 대한 여론을 관리하고 조성하는 서비스로 이슈 확산과 관리에 효과적입니다.</div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="circle">
                                온라인 플랫폼
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                연령층 별 관리
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                타겟층 별 관리
                            </div>
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            발생된 여론의 파급력을 낮추는 효과
                        </div>
                        <div className="border-box">
                            새로운 여론을 형성하는데 핵심적인 역할
                        </div>
                        <div className="border-box">
                            전문성과 소통을 중심으로 여론 형성
                        </div>
                    </div>
                </div>
            </div>
            <div id="press" className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">공론화의 대표 미디어 활동</span>
                        <span className="h-2">기자 회견</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/6.png" alt="사진"/>

                    <div className="post text-center">
                        <div>기자 회견은 문제 및 이슈를 공론화 시키는데 매우 효과적인 결과를 나타냅니다.</div>
                        <div>각기 다른 언론사와 방송사 등 다양한 미디어 활동을 집중시켜 짧은 시간 안에</div>
                        <div>파급력을 가져올 수 있도록 프레스를 집중합니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">지속적인 전문가 브랜딩 활동</span>
                        <span className="h-2">칼럼니스트</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/5.png" alt="사진"/>

                    <div className="post text-center">
                        <div>칼럼니스트 활동은 전문가로서 정치, 사회 등 전문 분야에 있어 자신의 견해를 통해 비판, 시평을</div>
                        <div>주로 진행하게 됩니다. 자신의 견해가 신문, 잡지에 기재되며 전문가로서 공신력을 높일 수 있으며</div>
                        <div>정기 기제의 경우 '고정 독자'가 생기게 되며 개인이 가진 브랜드 가치를 상승시키는데 효과적입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">자연스러운 PR형성</span>
                        <span className="h-2">방송</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/7.png" alt="사진"/>

                    <div className="post text-center">
                        <div>PR을 진행하는 데 있어 대상자가 브랜드 형성에 부족함이 있지만 짧은 시간 안에 효과를 날 때</div>
                        <div>가장 효율적인 방법이 될 수 있는 것은 '방송'입니다.</div>
                        <div>다양한 방송이 있어 분야 선택의 폭이 넓으며 짧은 시간 안에 이슈를 형성할 수 있으며</div>
                        <div>전문가 중에 전문가로 브랜딩하는 효과를 나타낼 수 있습니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">자연스러운 PR형성</span>
                        <span className="h-2">라디오</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/report_component/4.png" alt="사진"/>

                    <div className="post text-center">
                        <div>라디오는 방송에 비해 대표 채널이 적기 때문에 다양한 타켓에게 PR하는 효과를 전달할 수 있습니다.</div>
                        <div>하지만, 라디오를 시청하는 연령층이 한정적이기 때문에 특정 계층에게만 PR활동이 가능합니다.</div>
                        <div>라디오를 시청하는 이들의 전파 방식은 주로 '입소문'이기에 더욱 확실한</div>
                        <div>지지층을 얻을 수 있다는 장점이 있습니다.</div>
                    </div>

                    <div className="header text-center">
                        <span className="h-1">언론보도</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <PressComponent/>
                </div>
            </div>
        </div>
    }
}

export default ReportComponent;