import React from 'react';
import './PRComponent.css';

import $ from 'jquery';

import Jimp from 'jimp';

class PRComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            forceRender: 0,
            inputName: "",
            inputEmail: "",
            inputAtsign: "",
            inputTitle: "",
            inputDesc: "",
        }
    }

    forceRender = function() 
    {
        var num = this.state.forceRender + 1;
        this.setState({forceRender:num});
    }
    changedinputName = (event) => 
    {
        this.setState({inputName:event.target.value});
    }
    changedinputEmail = (event) => 
    {
        this.setState({inputEmail:event.target.value});
    }
    changedinputAtsign = (event) => 
    {
        this.setState({inputAtsign:event.target.value});
    }
    changedinputTitle = (event) => 
    {
        this.setState({inputTitle:event.target.value});
    }
    changedinputDesc = (event) => 
    {
        this.setState({inputDesc:event.target.value});
    }

    changedinputAtsignFunction = () => 
    {
        const value = $("#atsign-select").val();
        if (value !== "직접입력") {
            this.setState({inputAtsign: value});
            $("#atsign").attr("readonly", "readonly");
        } else {
            this.setState({inputAtsign: ""});
            $("#atsign").removeAttr("readonly");
        }

    }

    handleFileSelect = () => {
        const files = $('#input-file')[0].files;
        const file = files[0];
        if(files && file) {
            const reader = new FileReader();
            reader.onload = async function(event) {
                const data = event.target.result;
                const buffer = Buffer.from(btoa(data), 'base64');
                const image = await Jimp.read(buffer).then(image => {
                    return image
                    .resize(160, 160)
                    .quality(40);
                });
                const exportData = await image.getBase64Async(Jimp.AUTO);
                $('#input-code').val(exportData);
            }
            reader.readAsBinaryString(file);
        }
    }

    displayInput = function() 
    {
        if ($("#checkAgree").is(":checked") === false) {
            return false;
        }

        var string = [];
        string.push(String(this.state.inputName).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputEmail).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputAtsign).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputTitle).replace(/^\s+|\s+$/g, ''));
        string.push(String(this.state.inputDesc).replace(/^\s+|\s+$/g, ''));

        for (var i = 0; i < 5; i ++) {
            if (!(string[i].length >= 1)) {
                return false;
            }
        }
        return true;
    }

    componentDidMount()
    {
        $("#checkAgree").click(function()
        {
            this.forceRender();
        }.bind(this));
    }

    render()
    {
        var inputSubmit = this.displayInput() === false ? <div>확인</div> : <input type="submit" value="확인"/>;

        return (
            <div id="question">
                <div className="banner-wrap">
                    <img className="banner" src="images/question_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">제휴문의</h1>
                    </div>
                </div>

                <div className="container text-left">
                    <div className="header">넛지스토리에서는 개인·단체의 새로운 제안과<br/>소중한 제휴 문의를 기다립니다.</div>

                    <ul className="sub-header">
                        <li>넛지스토리와 함께하고자 하는 모든 내용에 대해서 제안을 해주시면 성실히 응답드리겠습니다.</li>
                        <li>회사명, 담당자, 연락처, 제안/제휴에 대한 내용을 필히 적어주시기 바라며 상기 내용을 적지 않으실 경우 답변이 가지 않을 수 있음을 양지하시어 기본적인 내용을 작성해주시기 바랍니다.</li>
                    </ul>

                    <form method="post" action="https://script.google.com/macros/s/AKfycbyRqHRiTH_98mBWlQ8LHHq8emSFKf1bqbsmP9s0yme-TXjhZTZs/exec">
                        <input className="no-visit" name="문의 구분" value="제휴 문의" readOnly/>
                        
                        <div className="form-group mb-3">
                            <select name="제휴 종류" className="form-control custom-form-control-1" id="switch-select">
                                <option>선택</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>

                        <div className="form-border">
                            <div className="row mt-3 mb-4">
                                <label className="col-3 text-center">
                                    이름
                                </label>
                                <div className="col-auto text-left">
                                    <input name="이름" className="form-control custom-form-control-2" maxLength="10" type="text" onChange=
                                    {
                                        this.changedinputName
                                    }/>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3 text-center">
                                    이메일
                                </div>
                                <div className="col-auto text-left">
                                    <input name="이메일" className="form-control custom-form-control-2" maxLength="15" type="text" onChange=
                                    {
                                        this.changedinputEmail
                                    }/>
                                    <span className="ml-1 mr-1">@</span>
                                    <input id="atsign" name="이메일 주소" className="form-control custom-form-control-2" maxLength="15" type="text" value={this.state.inputAtsign} onChange=
                                    {
                                        this.changedinputAtsign
                                    }/>
                                    <select id="atsign-select" className="form-control ml-1 d-inline-block custom-form-control-1" onChange={
                                        this.changedinputAtsignFunction
                                    }>
                                        <option defaultValue="">직접입력</option>
                                        <option value="naver.com">네이버</option>
                                        <option value="nate.com">네이트</option>
                                        <option value="dreamwiz.com">드림위즈</option>
                                        <option value="lycos.co.kr">라이코스</option>
                                        <option value="empal.com">엠파스</option>
                                        <option value="chol.com">천리안</option>
                                        <option value="korea.com">코리아닷컴</option>
                                        <option value="paran.com">파란닷컴</option>
                                        <option value="freechal.com">프리챌</option>
                                        <option value="hanafos.com">하나포스</option>
                                        <option value="hitel.com">하이텔</option>
                                        <option value="hanmir.com">한미르</option>
                                        <option value="netian.com">네티앙</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3 text-center">
                                    제목
                                </div>
                                <div className="col-9 text-left">
                                    <input name="제목" className="form-control custom-form-control-3" maxLength="20" type="text" onChange=
                                    {
                                        this.changedinputTitle
                                    }/>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3 text-center">
                                    내용
                                </div>
                                <div className="col-9 text-left">
                                    <textarea name="내용" className="form-control custom-form-control-4" maxLength="140" type="textarea" onChange=
                                    {
                                        this.changedinputDesc
                                    }/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3 text-center">
                                    첨부파일
                                </div>
                                <div className="col-auto text-left">
                                    <div className="form-group">
                                        <input id="input-file" name="첨부파일 이름" type="file" accept="image/*" className="form-control-file" onChange=
                                        {
                                            this.handleFileSelect
                                        }/>
                                    </div>
                                </div>
                                <input id="input-code" className="no-visit" name="첨부파일" readOnly/>
                            </div>
                        </div>

                        <div className="agree-text">개인정보 수집 및 이용 동의 안내</div>
                        <ul className="agree-desc">
                            <li>수집 주체: 넛지스토리</li>
                            <li>수집 항목: 이름, 이메일</li>
                            <li>수집 목적: 제안 내용의 확인 및 답변 처리</li>
                            <li>보유기간: 제안 접수 후 3개월 이내 파기함</li>
                        </ul>

                        <div className="form-check vertical">
                            <input name="개인정보 동의여부" className="form-check-input" type="checkbox" value="동의함" id="checkAgree"/>
                            <label className="form-check-label ml-lg-0 ml-2" htmlFor="checkAgree">
                                개인정보 수집 및 이용에 대해서 동의합니다.
                            </label>
                        </div>

                        <div className="send-form">
                            {inputSubmit}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PRComponent;