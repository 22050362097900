import React from 'react';
import "./PortalComponent.css";

class PortalComponent extends React.Component
{
    render()
    {
        return <div id="portal" className="top-padding">
            <div className="container">
                <div className="container-small">
                <div className="header">
                        <span className="h-1">모든 정보를 연결하는 통로</span>
                        <span className="h-2">포털</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-12">
                            <img className="img-width" src="images/service_component/6.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/7.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/8.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 사용빈도가 가장 높은 플랫폼중 하나로 네이버, 구글, 다음을 꼽을 수 있습니다.</div>
                        <div>해당 포털은 이슈의 검색에 특화되어 있어 기업 PR진행 시 필수적으로 활용해야 하는 플랫폼입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">검색의 최적화 포털</span>
                        <span className="h-2">포털</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/portal_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>일반인들이 가장 많이 활용하는 검색 플랫폼의 특징은 ‘신뢰성’입니다.</div>
                        <div>또한, 검색 플랫폼 특성상 적응된 디스플레이로 인해 플랫폼 이동이 드물어 반 지속적으로 사용한다는 특성을 가지고 있습니다.</div>
                        <div>
                            대형 플랫폼에서 활용하는 구성인 블로그, Q&A, 포스트, 이미지, 미디어, 웹문서를
                            활용한 다양한 구성을 통해 분야별 특색에 맞는 PR을 하나의 플랫폼을 통해 활용할 수 있다는 것이 특징입니다.
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            포털을 통한 다양한 연령층 다방면 노출
                        </div>
                        <div className="border-box">
                            검색시 기본이 되는 플랫폼으로 검색량 ▲
                        </div>
                        <div className="border-box">
                            정보 획득의 가장 기본이 되는 포털
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default PortalComponent;