import React from 'react';
import "./PressComponent.css";

class PressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serch: "",
            serchMode: 0,
            serchSort: 0,
            data: []
        }
    }

    ArrayTitleSort(a, b) {
        if(a.name === b.name) {
            return 0;
        } 
        return a.name > b.name ? 1 : -1; 
    }
    ArrayDescriptionSort(a, b) {
        if(a.description === b.description) {
            return 0;
        } 
        return a.description > b.description ? 1 : -1; 
    }
    ArrayDateSort(a, b) {
        if(a.date === b.date) {
            return 0;
        } 
        return a.date > b.date ? 1 : -1; 
    }

    ArrayCreate(data) {
        let array = this.state.data;
        array.push(data);
    }

    ArrayPush() {
        let array = this.state.data;
        let serch = this.state.serch;
        switch(parseInt(this.state.serchSort)) {
            default: break;
            case 0: array.sort(this.ArrayDateSort).reverse(); break;
            case 1: array.sort(this.ArrayDateSort); break;
            case 2: array.sort(this.ArrayTitleSort); break;
            case 3: array.sort(this.ArrayDescriptionSort); break;
        }
        let mode = parseInt(this.state.serchMode);
        let list = [];
        for (let i = 0; i < array.length; i ++) {
            if (serch !== "") {
                switch(mode) {
                    default: continue;
                    case 0: 
                        if (array[i].name.indexOf(serch) === -1) {
                            continue;
                        }
                    break;
                    case 1: 
                        if (array[i].description.indexOf(serch) === -1) {
                            continue;
                        }
                    break;
                    case 2:
                        if (array[i].date.indexOf(serch) === -1) {
                            continue;
                        }    
                    break;
                }
            }
            
            list.push(<div key={i} className="col-12">
                <div className="card">
                    <div className="row no-gutters">
                        <div className="image-col col-lg-3 col-4">
                            <img src={"images/press_component/" + array[i].image} className="card-img-top h-100" alt="이미지"/>
                        </div>
                        <div className="col-lg-9 col-8">
                            <div className="card-body">
                            <a href={array[i].link} target="_blank" rel="noopener noreferrer"><h5 className="card-title">{array[i].name}</h5></a>
                                <p className="card-text">{array[i].description.substr(0, 100) + "..."}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{array[i].date}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }

        let count = serch !== "" && list.length > 0 ? <div className="alert alert-warning px-1 py-0 ml-2" role="alert">
            탐색 {list.length}개
        </div> : "";

        list.unshift(<div key={-1} className="d-flex">
            <div className="alert alert-success px-1 py-0" role="alert">
                전체 {array.length}개
            </div>
            {count}
        </div>);
        return list;
    }

    componentDidMount() {
        this.ArrayCreate({
            name: "열린사람들크리에이터사관학교(열크사), 기초수급 대상자 전액장학금 지원", 
            description: "열린사람들크리에이터사관학교(이하 열크사)가 지난 1일 기초수급자 대상자 박 모 씨에게 전액장학금을 전달했다. 열크사는 전문 유튜버 양성 에이전시 기관이다. 주 교육 프로그램으로는 1달 완성 전문 유튜버 과정이다. 이는 유튜브 기획 및 채널 방향성과 브랜드 형성(국내 1호 개인브랜드 매니저 국도형 교수), 영상편집(열린사람들 총괄PD 유회중 교수), 유머코칭(MBC 개그맨 김보규 교수 예명 MC캉캉), 자기관리(미스비키니코리아 챔피언 이혜진 교수)등으로 한달 교육으로 즉시 유튜버로 활동 할 수 있도록 만들어 주는 국내 유일 교육 프로그램이다.",
            image: "0.jpg",
            link: "http://www.gyotongn.com/news/articleView.html?idxno=227943", 
            date: "2019.04.15"
        });
        this.ArrayCreate({
            name: "열린사람들 크리에이터 사관학교(열크사), ‘2주 완성 유튜브 전문 편집과정’ 개설", 
            description: "열크사는 MBC 경성판타지, ABC뉴스, 로이터통신, TV조선 등 다양한 매체에 대한민국 대표 유튜브 교육기관으로 소개된 국내 최초 유튜브 전문 교육 에이전시이다. 본 기관 출신에는 유튜버 14만 구독자를 보유한 ‘허준할매건강TV’외 30여명의 크리에이터들이 있다. 현재도 왕성히 활동 중이다.",
            image: "1.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=5701", 
            date: "2020.02.20"
        });
        this.ArrayCreate({
            name: "열린사람들 크리에이터 사관학교, 'MBC예능 경성판타지에 소개'",
            description: "국내 유일 전문 유튜버 양성 에이전시 기관 ‘열린사람들 크리에이터 사관학교’가(이하 열크사) 지난 8일, MBC 예능 경성판타지에 방영 됐다. MBC ‘경성판타지’는 지방 사람들이 자신의 꿈을 쫒아 서울을 방문하면서 벌어지는 이야기를 담은 관찰예능 TV프로그램이다.",
            image: "2.png",
            link: "http://www.nbnnews.co.kr/news/articleView.html?idxno=266597", 
            date: "2019.05.10"
        });
        this.ArrayCreate({
            name: "유투버 양성 전문 교육기관은?...'열린사람들 크리에이터 사관학교'",
            description: "1인 미디어시대, 유투버 양성 전문 기관으로 지상파에서 소개된 유투버 양성 전문 에이전시 교육기관 '열린사람들 크리에이터 사관학교 (이하 '열크사')가 주목을 받고 있다. 열크사는 지난 11일 TV조선 7뉴스에 유튜버 전문기관으로 소개됐고 지난 8일에는 MBC 예능 ‘경성판타지’와 영국 로이터 통신 등 다양한 매체에 출연해 화제를 모았다.",
            image: "3.png",
            link: "http://www.nbnnews.co.kr/news/articleView.html?idxno=269177", 
            date: "2019.05.21"
        });
        this.ArrayCreate({
            name: "유튜버 양성 전문 교육기관 ‘열린사람들 크리에이터 사관학교’, 5기 수강생 수료", 
            description: "유투버 교육 전문 기관 '열린사람들 크리에이터 사관학교'(이하 '열크사')는 5기 수강생 수료식을 지난 30일 성료했다고 19일 밝혔다. 이번에 수료한 5기수는 한의학박사, 소상공인, 청년창업가 등등 다양한 분야들의 수강생들로 구성되어 있어 향후 자신들의 전문 분야에서 두각을 나타낼것으로 기대된다. 5기 수강생 조 모 씨는 “골프선수가 되기 위해 많은 시간들을 투자했지만 정말 내가 원하는 미래가 아닌 것 같았다”며 “유튜브를 통해 내가 정말 하고 싶은 것이 무엇인가를 찾고 싶었고 열크사를 통해 이를 해결했다”고 말했다.",
            image: "4.png",
            link: "http://www.nbnnews.co.kr/news/articleView.html?idxno=282417", 
            date: "2019.06.19"
        });
        this.ArrayCreate({
            name: "열린사람들 크리에이터 사관학교(열크사), “ABC News 국내 대표 유튜브 교육기관 소개”", 
            description: "국내 유일 전문 유튜버 양성 교육기관 ‘열린사람들 크리에이터 사관학교’(이하 열크사)가 지난 26일, 미국 민영방송사 ABC News에 한국 대표 유튜브 교육기관(유튜브 학원)으로 방영됐다. ABC News에서 열크사는 대한민국의 유튜브 열풍과 함께 한국에서 ‘유튜브 배우기’ 대표 유튜브 학원으로 소개했다.",
            image: "5.png",
            link: "http://www.nbnnews.co.kr/news/articleView.html?idxno=285834", 
            date: "2019.06.27"
        });
        this.ArrayCreate({
            name: "유튜버 전문 에이전시 교육 기관 '열린사람들 크리에이터 사관학교', 4기 수강생 수료", 
            description: "열린사람들 크리에이터 사관학교(이하 열크사) 4기 수강생들의 수료식이 9일 열렸다. 열크사는 유튜버 전문 유튜버 에이전시 기관으로 이번 기수는 화목반, 수목반 2개 반으로 10명 소수정예로 구성됐다. 열크사는 매 기수 별로 경제적으로 어려움이 있는 사람이나 유튜버로 끼가 넘치는 사람 1~2명을 선발해 전액 장학금을 지원하고 있다. 이번 4기 생 중에서는 하모 씨가 전액장학금 혜택을 받았다.",
            image: "6.png",
            link: "http://www.nbnnews.co.kr/news/articleView.html?idxno=266601", 
            date: "2019.05.10"
        });
        this.ArrayCreate({
            name: "열크사 , 대한민국기록인증원 국내최다 수익창출 유튜버 양성기관 인증", 
            description: "유튜브 전문 교육에이전시 열크사가 지난 10일, 대한민국최고기록인증원으로부터 대한민국 최초 국내 최다 수익창출 유튜버 양성기관을 인증 받았다고 16일 밝혔다. 열크사는 30만 유튜버 허준할매건강TV를 탄생시키며, 도형선생, 물불남, 웨딩오빠 등등 20여명의 수익창출 유튜버를 탄생시킨 유튜브 전문 교육에이전시이다. 이런 성과를 인정받아 작년에는 ABC뉴스, 로이터통신 등 해외언론에서도 유튜브 교육 현장을 취재하기도 했다. 또한 MBC 예능 경성판타지 출연, TV조선 7뉴스에 유튜브 교육관련 취재를 한 바 있다.",
            image: "1.jpg",
            link: "http://www.newscape.co.kr/news/view.php?idx=49274", 
            date: "2020.07.16"
        });
        this.ArrayCreate({
            name: "유튜버부터 DJ까지…인생 2막 돕는 이색학원 눈길", 
            description: "최근 유튜브나 디제잉 등을 가르쳐주는 이색학원에 대한 관심이 뜨겁다. 한 유튜브 학원의 경우 15명 정원인 강좌에 150명 이상의 인원이 몰릴 정도로 입학조차 쉽지 않다. 유튜브 크리에이터나 디제이(DJ) 등 유망직종으로 전업을 희망하는 40~50대 중장년층은 물론 취업난에 시달리는 20대 젊은층까지 다양한 연령대로부터 인기를 끌고 있다.",
            image: "2.jpg",
            link: "http://www.skyedaily.com/news/news_view.html?ID=88128", 
            date: "2019.02.21"
        });
        this.ArrayCreate({
            name: "열크사 출신 유튜버 현지인TV, 수익창출 달성", 
            description: "열크사 출신 유튜버 현지인TV가 지난 17일 수익창출을 달성했다. 유튜브 현지인TV는 열크사 3기 수익창출 반 출신 수강생이다. 주 콘텐츠는 은퇴 후 캠핑생활로 구성되어 있다. 유튜버 현지인TV를 탄생시킨 열크사는 국내 유일 유튜브 전문 에이전시이다. MBC 경성판타지, ABC뉴스 취재보도, 로이터통신 취재보도 등 국내 뿐만 아니라 전세계적으로 유튜브 교육으로 조명을 받은 기관이다. 또한 28만 유튜버 허준할매건강TV를 탄생시키며 기염을 토했다.",
            image: "7.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=21652", 
            date: "2020.07.08"
        });
        this.ArrayCreate({
            name: "허준할매가 당부하는 “넣어주고, 돌려주고, 녹여줘야 건강해지는 것은?“", 
            description: "지난29일, 30만 유튜버 허준할매건강TV 허준할매(본명 최정원)가 “넣어주고, 돌려주고, 녹여줘야 건강해지는 것”에 대해 업로드했다. 영상에서 허준할매는 넣어줘야하는 것에 양질의 혈액이라고 밝혔다. 더해서 혈액이 하는일과 부족하면 걸리는 병, 보혈 약재에 대해 말했다. 돌려줘야하는 것에는 혈행이라고 했다. 많고 좋은 피가 있어도 혈액순환이 안되면 병을 유발하며, 밀고 당기고를 잘해줘야 된다고 당부했다. 또한 혈행 부족 시 걸리는 병과 혈행 활혈 약재에 대해 소개했다.",
            image: "8.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=25030", 
            date: "2020.07.30"
        });
        this.ArrayCreate({
            name: "열크사, 수익창출 유튜버 간담회 ‘열크사 멤버스’ 오는 24일 예정", 
            description: "국내 유일 유튜브 교육 에이전시 열크사가 오는 24일, 출신 수익창출 유튜버와 정기 간담회를 갖는다. 본 간담회는 ‘열크사 멤버스’로 ‘수익창출’ 과정을 수료한 유튜버들만 참석 가능하다. 열크사 멤버스는 출신 유튜버들의 간담회 자리로 상호 간의 교류를 취지로 탄생한 행사이다. 이번 간담회는 코로나19 확산 방지를 위해 철저한 방역 속에서 진행될 예정이다. 최근 간담회에서는 열크사 3기 출신 28만 유튜버 허준할매건강TV 최정원 한의학 박사를 비롯해 10여명의 유튜버들이 참석했다.",
            image: "0.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=22392", 
            date: "2020.07.14"
        });
        this.ArrayCreate({
            name: "유튜브 교육 열크사, 4차산업혁명 K-스타트업 30 선정", 
            description: "유튜브 교육 열크사(열린사람들 크리에이터사관학교)가 지난 5일, 전 정보통신부 장관 진대제와 국회미래정책연구회 회장 정갑윤와 국제미래학회 대표 안종배와 도전한국인 대표 조영관이 주최하는 '4차산업혁명 K-스타트업 30'으로 선정됐다. 열크사는 유튜브 학원이 아닌 유튜브 교육기관이다. 최근에 영국 로이터통신, 미국ABC뉴스, MBC예능 경성판타지, TV조선 7시뉴스에 우리나라 대표 유튜브 교육기관으로 소개됐다.",
            image: "9.png",
            link: "http://www.research-paper.co.kr/news/articleView.html?idxno=185120", 
            date: "2019.08.02"
        });
        this.ArrayCreate({
            name: "열크사 출신 ‘유튜버 택시Q’, 3주 수익창출 달성", 
            description: "국내최초 유튜브 전문 교육에이전시 열크사(열린사람들 크리에이터 사관학교)에서 지난 20일 수익창출 유튜버를 탄생시켰다고 밝혔다. 유튜브 채널 이름은 ‘택시Q’이다. 택시Q는 현직 택시 운전사 박용규 씨가 직접 택시 노하우를 전달하는 유튜브 채널이다. 박 씨의 유튜브 수익창출은 3주만에 달성한 쾌거이다. 열크사 편집 교육 담당 이수열 교학팀장은 “택시Q 박용규 선생님은 4주 수익창출보장Class(프로반)출신이다. 교육 수료 후에도 주기적으로 편집과 콘텐츠에 대해 사후관리를 적극적으로 받으셨는데, 작은 결과로 단기간에 수익창출이 되셔서 기쁘다”며 “택시Q의 경우 허준할매건강TV 이후 시니어 세대가 성공을 거두어 여러 가지 의미를 갖는다”고 전했다.",
            image: "10.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=11430", 
            date: "2020.04.29"
        });
        this.ArrayCreate({
            name: "열크사 출신 ‘유튜브 화인미디어TV’, 채널 개설 3개월 만에 수익창출 유튜버로", 
            description: "국내최초 유튜브 전문 교육에이전시 열크사(열린사람들 크리에이터 사관학교)가 지난 15일 수익창출 유튜버를 탄생시켰다. 열크사 출신 해당 유튜버는 채널 개설 후 3개월 만에 수익창출을 달성했다. 본 유튜브는 ‘화인미디어TV’ 채널명으로 열크사 11기 출신이다. 자동차 정보와 시사 등을 콘텐츠로 운영 중이다.",
            image: "11.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=13275", 
            date: "2020.05.18"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 김세진 문화콘텐츠 자문위원 위촉", 
            description: "한국사회공헌협회(회장 국도형)가 지난 1일, 문화콘텐츠 자문위원 위촉됐다. 이번에 위촉된 김세진 문화콘텐츠 자문위원은 대한문회체육진흥회 회장 출신으로 사단법인 두드림스포츠 사무국장, 대한브라질리언주짓수연맹 부회장, 한국열린사이버대학교 뷰티건강디자인학과 특임교수를 역임 중인 인물이다. 위촉에 앞서 김세진 문화콘텐츠 자문위원은 “2018년 심청이 캠페인을 통해 한국사회공헌협회와 함께 사회공헌 캠페인을 함께한 적이 있다”라며 “당시 청년들이 직접 어르신 분들게 봉사활동을 하고 연결고리 역할을 하는 것을 보며 합류 날짜를 기다렸다”라고 위촉된 배경에 대해 말했다.",
            image: "4.jpg",
            link: "http://www.onews.tv/news/articleView.html?idxno=35897", 
            date: "2020.10.16"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회 X 오엔오(ONO), 이현규의 내손내만 헤어인싸템 캠페인 예정", 
            description: "한국사회공헌협회(회장 국도형)가 오는 24일, 쌍문동에서 오엔오(ONO)와 오프라인 캠페인을 재개한다. 오프라인 캠페인은 올해 처음으로 진행된다. 오엔오(ONO)는 핸드메이드 브랜드이다. 본 캠페인은 내손내만 헤어인싸템 명으로 내 손으로 내가 만드는 헤어악세사리 활동이다. 캠페인을 위해 오엔오(ONO) 이현규 대표가 직접 참여하며 청년챔프단이 함께한다. 이번 캠페인 장기화된 코로나19 확산으로 인해 침체된 핸드메이드 시장을 살리는 취지로 진행된다.",
            image: "5.jpg",
            link: "http://www.onews.tv/news/articleView.html?idxno=35901", 
            date: "2020.10.16"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 송인규 ICT 자문위원 위촉", 
            description: "한국사회공헌협회(회장 국도형)가 지난 1일, 송인규 ICT 자문위원을 위촉했다고 밝혔다. 이번 ICT 자문위원 위촉은 강태웅 패션이사의 추천으로 진행됐다. 강태웅 패션이사는 “젊은 자문위원이자 현 동향을 주의 주시하는 ICT 분야 종사자가 협회에 있으면 좋겠다는 생각을 했다”라고 추천 계기에 대해 전했다. 이어 송인규 ICT 자문위원은 “큰 재능은 아닐 수 있지만, 한국사회공헌협회와 청년챔프단을 연결시키는 앱을 만들어 시대를 앞서가는 사회공헌활동에 이바지하고 싶다”라고 말했다.",
            image: "6.jpg",
            link: "http://www.onews.tv/news/articleView.html?idxno=35900", 
            date: "2020.10.16"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 이상희 부산지회장 위촉", 
            description: "한국사회공헌협회(회장 국도형)가 지난 1일 무브핏 이상희 대표를 부산지회장으로 위촉했다. 이는 협회 측 최초 지방 지회 설립 및 지회장 위촉이다. 이번에 위촉된 이상희 부산지회장은 무브핏 대표, 웰티 대표, 스승의날영어연구소 부대표, 국내 1호 컨디션관리 전문가 등의 이력을 지닌 인물이다. 이번 위촉은 국도형 회장과 사무처의 3개월 간 논의 끝에 결정됐다. 이상희 부산지회장 위촉에 앞서 국도형 회장은 “수도권을 제외한 곳에서 지회를 열고 싶다고 수많은 제안이 있었지만 사회공헌활동을 진정성 있게 함께 하실 분을 고르기는 정말 어려운 일이였다”라며 “이상희 지회장 역시 오랜 시간 지켜봤고, 최종 결정을 하는데 3개월 정도 더 관찰하여 협회와 뜻을 같이 하실 수 있는 분이라는 것을 확신하게 되어 함께하게 됐다.”라고 이상희 부산지회장의 위촉과정에 대해 말했다.",
            image: "12.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=35396", 
            date: "2020.10.14"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회 청년챔프단, 사회공헌보고회", 
            description: "한국사회공헌협회(회장 국도형) 청년챔프단이 지난 25일, 암사동 사옥에서 제 20회 사회공헌보고회를 성황리에 마쳤다. 이번 보고회는 코로나19 예방을 위한 철저한 방역 속에서 분과장 및 팀장들만 참석한 가운데 온라인 화상회의로 생중계됐다. 20회 보고회의 주요 안건은 ‘코로나19 대비 사회공헌활동’이었다. 이와 관련돼서 청년챔프단 내부회의가 함께 진행됐다. 회의 결과 ‘내부 결속 및 코로나19 백신 개발 전까지 소수 신규단원 모집’으로 결정됐다. 청년챔프단 이수열 부단장은 “코로나19 사태 이후 잠정적으로 오프리인 활동이 중단된 상태이다”며 “이후 활동에도 방역을 위해 오프라인 활동은 어려울 것으로 판단되어 내부 결속을 다지는 것에 올해 목표를 변경했다”고 말했다.",
            image: "13.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=24446", 
            date: "2020.07.28"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회 청년챔프단, 19회 사회공헌보고회 성료", 
            description: "한국사회공헌협회(회장 국도형) 청년챔프단이 지난 27일, 암사동 사옥에서 제 19회 사회공헌보고회를 성황리에 마쳤다. 이번 보고회는 코로나19 바이러스 방역 속에서 분과장 4인만 참석한 가운데 철저한 방역 속에서 진행됐다. 행사는 4개월 만에 진행되어 그동안의 온라인기반 사회공헌활동에 대한 발표가 진행됐다. 발표는 Webex를 통해 실시간으로 중계됐다. 청년챔프단 김선재 단장은 “온라인으로 사회공헌보고회를 처음으로 시도하다 보니 시행착오가 많았다”며 “코로나19 바이러스라는 열약한 상황 속에서 꾸준히 사회공헌 문화 활성화를 위해 온라인으로 활약해준 청년챔프단 모두에게 감사드린다”고 전했다. ",
            image: "14.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=21653", 
            date: "2020.07.08"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 제 5회 심청이 캠페인 시즌 2 성료", 
            description: "한국사회공헌협회(회장 국도형)가 지난 26일 강동데이케어센터(그린케어)에서 제 5회 심청이 캠페인 시즌2를 성황리에 마쳤다고 밝혔다. 이날 캠페인은 청년챔프단 10여명이 함께해 세대 간의 소통을 이끌었다. 청년챔프단 성안나 단원은 “캠페인을 위해 새벽 기차를 타고 서울로 올라왔다”며 “몸은 지치고 힘들지만 어르신분들게 작게나마 도움을 드릴 수 있어 매우 기쁘다”고 말했다.",
            image: "15.png",
            link: "http://www.aitimes.com/news/articleView.html?idxno=121113", 
            date: "2019.10.30"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 청년챔프단 6기 예비단원 선발", 
            description: "한국사회공헌협회(회장 국도형)가 지난 26일, 청년챔프단 6기 최종선발을 마쳤다. 청년챔프단 6기 예비단원 최종선발은 비대면 영상면접을 통해 최종 선발됐다. 이번 선발은 1차 서류면접과 2차 영상 비대면 면접으로 진행됐다. 2차 영상 비대면 면접은 코로나19 재확산 조짐에 따라 기존 대면 면접에서 변경됐다. 최종 지원자는 358명으로 1차에서 180명, 2차에서 81명이 최종 예비단원으로 선발됐다. 이들은 이후 예비단원 미션을 통해 최종 정식단원으로 합류된다.",
            image: "16.png",
            link: "http://www.onews.tv/news/articleView.html?idxno=30705", 
            date: "2019.09.09"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회, 제4회 심청이 캠페인 시즌2 성료", 
            description: "한국사회공헌협회(회장 국도형)가 지난 21일, 강동데이케어센터(그린케어)에서 심청이 캠페인 시즌2 4번째 활동을 성황리에 마쳤다. 심청이캠페인은 청년들이 직접 노인재활프로그램을 제작하여 제공하는 활동이다. 이번 캠페인에는 청년챔프단 4기 예비단원이 처음 참여했다. 2기부터 4기까지 처음 함께한 캠페인이다. 이날 캠페인의 진행은 한국사회공헌협회 이동규 대외협력부장이 맡았다. 노인 1명 당 청년들이 3명이 배정되어 진행됐다.",
            image: "17.png",
            link: "http://www.aitimes.com/news/articleView.html?idxno=119694", 
            date: "2019.09.30"
        });
        this.ArrayCreate({
            name: "국도형 넛지스토리 대표, 마이너스 인생 끝…창업 3년만에 10억 벌다", 
            description: "젊은 기업인들이 질주하고 있다. 패기와 도전, 열정이 지금은 이들 기업인의 최고 자산이다. 그만큼 ‘창조적 파괴’를 지향하는 기업가 정신이 충일하다. 기업가 정신의 요체는 혁신이다. 때문에 이들 혁신가는 우리 산업에, 우리 사회에 창조정신을 고취시키고 실행력을 길러준다. 나아가 창업을 생각하는 2030세대에 자신감을 심어준다. 본지는 이런 특성을 고려해, 매주 금요일 인터뷰 시리즈 ‘예비 재벌’을 연재한다. 인터뷰이는 창업 1년이 지났고 종업원 10명 이상 근무하는 회사를 운영하는 만35세 미만으로 한정했다. 창조정신과 혁신, 실천력으로 무장된 이들 기업인 중 제2의 정주영, 이병철이 탄생하길 기대한다.",
            image: "7.jpg",
            link: "http://www.ekn.kr/news/article.html?no=203167", 
            date: "2016.03.04"
        });
        this.ArrayCreate({
            name: "‘넛지스토리’ 사람 냄새 나는 스토리텔링 ‘넛지의 밤’ 행사 열려", 
            description: "지난 2월 27일 콘텐츠 기반 마케팅 전문 기업 넛지스토리의 주최로 ‘응답하라 2015! 스토리가 있는 넛지의 밤’ 행사가 마포구의 메리골드호텔에서 열렸다. 신년을 맞이해 열린 이번 행사에서는 넛지스토리 모든 임직원이 한자리에 모여 서로의 노고를 격려하는 저녁 만찬 시간과 함께 향후 2015년 마케팅 방향에 대한 대담시간을 가졌다. 그간 웹툰이나 영상, CM송과 같은 순수 창작 콘텐츠에서부터 바이럴, SNS, 미디어 마케팅에 이르기까지 다양한 온라인 마케팅 영역을 다루어 왔던 넛지스토리는 ‘사람이 먼저다’를 사훈으로 ‘사람’과 ‘사람의 이야기’에 주목하며 스토리텔링의 진정성을 강조해왔다.",
            image: "8.jpg",
            link: "http://www.itdaily.kr/news/articleView.html?idxno=60710", 
            date: "2015.03.06"
        });
        this.ArrayCreate({
            name: "종합온라인마케팅기획사 넛지스토리, '중국마케팅 시장에서 첨병역할 할 것'", 
            description: "한류열풍을 타며 본격적으로 시작 된 중국인 관광객들의 국내 출입은 유명 커뮤니티 사이트 등에서 '요우커들의 습격' 이라고 표현될 정도로 가파른 상승세를 보이고 있다. 이는 침체돼 있던 국내 경제 상황에도 활기를 불어넣고 있다.올 한해 방문한 중국인 관광객 숫자만 약 400만명을 넘어설 정도로 그 열기가 매우 뜨겁다. 대부분의 중국 관광객들은 인천국제공항을 통해 입국하고 있으며특히 한국 관광의 성지순례처럼 여겨지는 서울관광은 명동거리에서 쇼핑하기, 강남 중심가 방문하기, 고궁 관람하기 등 프로그램화 될 정도로 큰 인기를 모으고 있다.",
            image: "9.jpg",
            link: "http://www.dt.co.kr/contents.html?article_no=2014121902109919807029", 
            date: "2014.12.19"
        });
        this.ArrayCreate({
            name: "스토리텔링형 웹툰 마케팅으로 트렌드 이끄는 넛지툰", 
            description: "인터넷 연재만화 ‘웹툰’을 사용한 스토리텔링형웹툰 마케팅이 주목 받고 있다. 조사에 따르면 네이버웹툰앱의 경우 월평균 이용시간 373분, 다음 웹툰앱은 259분으로 나타나며 이는 영상전문 사이트인 유튜브(146분)의 사용 시간을 훌쩍 넘기며 웹툰의 강한 파급력을 보여주고 있다. 이렇게 웹툰이 드라마, 영화 못지 않게 강한 힘을 뿜어내며 이를 활용한 웹툰 마케팅 저변도 점점 넓어지고 있는 추세다. 소비자와 소통하는 채널을 늘리고 거부감 없이 편하고 재미있는 이야기를 전달함으로써 웹툰 마케팅은 잠재 고객을 확보 할 수 있는 최고의 무기로 평가 받고 있다. 그 중심에 스토리텔링형웹툰 마케팅으로 새로운 열풍을 불러일으키고 있는 ‘넛지스토리’가 있다.",
            image: "10.jpg",
            link: "http://www.enewstoday.co.kr/news/articleView.html?idxno=379762", 
            date: "2015.04.02"
        });
        this.ArrayCreate({
            name: "브랜드 웹툰제작의 스토리텔링화, 진화하는 넛지툰 화제", 
            description: "대한민국은 지금 웹툰 전성시대에 살고 있다고 해도 과언이 아닐 정도로 ‘웹툰’이라는 콘텐츠는 이미 대중들의 문화 깊은 곳까지 스며들어 있다. One Source Multi Use 콘텐츠로서 광고, 영화, 드라마, 캐릭터 산업 등에 이르기까지 이미 다양한 분야에 걸쳐 재생산 되어지고 있을 뿐만 아니라 통계만 보아도 한국콘텐츠진흥원에 따르면 2014년 기준 웹툰시장 규모는 1719억원 수준으로 전년 1500억원 대비 15%가량 성장했다고 한다. 이러한 웹툰의 거대한 성장과 함께 기업들 또한 자신들의 브랜드를 알리기 위한 마케팅의 일환으로 웹툰광고의 개념이 생겨나기 시작했는데 이와 맞물려 최근 주목 받고 있는 것이 바로 ‘넛지툰’이다.",
            image: "11.jpg",
            link: "http://www.cctvnews.co.kr/news/articleView.html?idxno=30454", 
            date: "2015.08.27"
        });
        this.ArrayCreate({
            name: "곱창먹으면 치킨주는 집, 노량진맛집으로 거듭난 청년곱창198", 
            description: "지난 3월에 오픈한 청년곱창198이 노량진 이색 맛집으로 입소문을 타고 있다. 노량진 번화가에서도 다소 떨어진 곳에 위치한 이 매장은 '곱창먹으면 치킨주는 집'이라는 이색적인 타이틀을 사용해 입지적인 불리함을 이겨내고 많은 노량진 곱창가게 사이에서 뚜렷한 성장세를 보이고 있다.",
            image: "12.jpg",
            link: "http://www.mhns.co.kr/news/articleView.html?idxno=60883", 
            date: "2017.06.13"
        });
        this.ArrayCreate({
            name: "청년문화포럼, 제3회 ‘컬쳐쇼’ 성황리에 마쳐", 
            description: "지난 31일 제3회 컬쳐쇼(주최: 청년문화포럼 / 후원: 대한북레터협회, 갤러리 카페 G아르체, (주)넛지스토리, 매거진 열린사람들 등)가 강남역 신분당선 내 위치한 갤러리 카페 G아르체에서 진행되었다. ‘컬쳐쇼‘는 비영리 청년단체 청년문화포럼(회장:황희두)이 월 1회 주최하는 정기행사로써 청년활동가들이 기획부터 진행까지 직접 도맡아 하는 파일럿 형식의 문화행사이다.",
            image: "13.jpg",
            link: "http://www.e2news.com/news/articleView.html?idxno=92169", 
            date: "2016.04.11"
        });
        this.ArrayCreate({
            name: "하루 600만명이 본다는 웹툰…광고 플랫폼으로 부상", 
            description: "하루 수백만 명이 찾는 웹툰이 모바일 시대 주목받는 광고 미디어로 떠올랐다. 광고업계에서 웹툰을 광고 플랫폼으로 활용하는 사례가 급격히 늘고 있는 것으로 나타났다. 23일 나스미디어 집계에 따르면 네이버 웹툰 이용자 수는 하루 평균 600만명, 월평균 2000만명에 달한다. 이용자 80% 이상이 모바일을 통해 웹툰을 찾는 것으로 조사됐다.",
            image: "14.jpg",
            link: "https://news.mt.co.kr/mtview.php?no=2015112312000008334&outlink=1&ref=https%3A%2F%2Fsearch.naver.com", 
            date: "2015.11.23"
        });
        this.ArrayCreate({
            name: "국내1호 개인브랜드매니저 국도형, 최고기록인증원(KBRL) 최초기록인증", 
            description: "한국사회공헌협회(회장 국도형)가 지난 21일, 강동데이케어센터(그린케어)에서 심청이 캠페인 시즌2 4번째 활동을 성황리에 마쳤다. 심청이캠페인은 청년들이 직접 노인재활프로그램을 제작하여 제공하는 활동이다. 이번 캠페인에는 청년챔프단 4기 예비단원이 처음 참여했다. 2기부터 4기까지 처음 함께한 캠페인이다. 이날 캠페인의 진행은 한국사회공헌협회 이동규 대외협력부장이 맡았다. 노인 1명 당 청년들이 3명이 배정되어 진행됐다.",
            image: "15.jpg",
            link: "http://www.newskr.kr/news/articleView.html?idxno=13966", 
            date: "2018.08.28"
        });
        this.ArrayCreate({
            name: "[에너지경제 오프라인] 먼저 만나는 에너지경제신문 헤드라인", 
            description: "◇산업-2 [예비 재벌이 뛴다②] 국도형 넛지스토리 대표 '스토리텔링의 힘…마이너스 인생이 10억 자산가로' 포스코 구조조정 맞아? 힐스테이트 평택2차 틈새면적 활용 높여",
            image: "16.jpg",
            link: "http://www.ekn.kr/news/article.html?no=203170", 
            date: "2016.02.25"
        });
        this.ArrayCreate({
            name: "매거진 열린사람들 국도형PD 농촌 스마트폰 및 SNS 활용 재능기부 봉사활동 참여해", 
            description: "국내 최초 소셜스타 토크쇼 ‘미개인’(제작사:열린사람들)의 인기 진행자 국도형PD가 농어촌 마을주민들을 위한 재능기부 활동에 동참하여 화제다. 지난 5일 전북 진안군 보래골 유기농밸리 주민들을 대상으로 한 이번 봉사활동은 농산어촌 주민들의 인터넷 활용과 시대적 변화에 적응하기 위한 스마트폰 활용교육을 위해 (사)농산어촌홍보개발원의 주관 하에 진행되었으며, 스마트폰 활용법, 블로그 제작방법, SNS 활용법 등에 대해 강의형식으로 약 2시간에 걸쳐 진행되었다.",
            image: "17.jpg",
            link: "http://www.nextdaily.co.kr/news/article.html?id=20151209800056", 
            date: "2015.12.10"
        });
        this.ArrayCreate({
            name: "KBJJF 대한브라질리언주짓수연맹 한국사회공헌협회 국도형 회장 부회장 위촉, 다양한 이색 경력 눈길", 
            description: "이 날 부회장으로 위촉 된 국도형 부회장은 한국사회공헌협회의 회장이자 퍼스널브랜드 매니지먼트 (주)넛지스토리의 대표이사로서, 국내1호 개인브랜드매니저로 활동중인 인물이다. 최근 저서 '당신의 몸값은 얼마입니까'(출판사:진한앰엔비)를 정식 발간하였으며, 디지털 싱글 앨범 '전화위복', 시사유튜버 '도형선생' JTBC 프로그램 오!아시스의 전문가 고정패널로 출연하는 등 다양한 문화 활동을 통해 사회적인 기여를 이어나가고 있다.",
            image: "18.jpg",
            link: "https://www.dailysecu.com/news/articleView.html?idxno=86400", 
            date: "2019.12.12"
        });
        this.ArrayCreate({
            name: "한국사회공헌협회 국도형 회장, 청년과 사회공헌으로 수를 놓다", 
            description: "최근 들어 대한민국은 더욱 청년 문제와 개인주의가 점점 심해지고 있다. 하지만 중요하다는 사실은 인지하지만 실천과 행동을 하는 사람은 거의 없다. 청년들에게 꿈과 희망을 전하기 위해 청년과 사회공헌에 대한 의미를 몸소 실천하고 전하는 청년문화포럼 상임부회장이자 한국사회공헌협회 국도형 회장을 인터뷰했다.",
            image: "19.jpg",
            link: "http://www.rpm9.com/news/article.html?id=20171024090042", 
            date: "2017.10.24"
        });
        this.ArrayCreate({
            name: "넛지스토리, 스토리텔링 온라인광고로 고객 사로잡다", 
            description: "종합 온라인광고대행사 ‘넛지스토리’가 광고주들과 소비자들의 입맛에 맞춘 리얼 스토리텔링 광고를 선보여 온라인 광고업계에서 화제를 모으고 있다. 리얼스토리텔링 광고란 흔히 블로그, SNS 등으로 대표되는 ‘바이럴마케팅(viral marketing)’ 방식에 전문작가의 스토리텔링이 결합된 형태를 말한다. 광고주가 제공한 사업스토리를 중심으로 웹툰이나 영상같은 참여형 콘텐츠 등을 제작해 소비자들 스스로가 공유, 전파할 수 있도록 흥미를 이끌어내어 자연스럽게 관심도를 끌어올리는 방식이다. ",
            image: "20.jpg",
            link: "https://www.anewsa.com/detail.php?number=660160&thread=09r02", 
            date: "2014.05.20"
        });
        this.ArrayCreate({
            name: "넛지스토리 주최 앱 공모전 ‘힘을내요 슈퍼파월’ 팀 대상", 
            description: "창작 콘텐츠 제작 전문기업 넛지스토리는 지난달 11일 본사 사옥에서 '제 1회 넛지스토리 창의융합 어플리케이션 공모전' 시상식을 개최했다. 창의융합 어플리케이션 공모전은 넛지스토리의 주최로 지난달 28일까지 약 한달 여간의 기간을 거쳐 총 32개 작품이 접수됐고 각계 각층의 대학생, 청년 앱 개발자들이 재능을 뽐내며 경합을 이뤘다.",
            image: "21.jpg",
            link: "https://www.anewsa.com/detail.php?number=824294&thread=10r03", 
            date: "2015.05.12"
        });
        this.ArrayCreate({
            name: "청년문화포럼, 제3회 ‘컬쳐쇼’ 성황리에 마쳐", 
            description: "지난 31일 제3회 컬쳐쇼(주최: 청년문화포럼 / 후원: 대한북레터협회, 갤러리 카페 G아르체, (주)넛지스토리, 매거진 열린사람들 등)가 강남역 신분당선 내 위치한 갤러리 카페 G아르체에서 진행되었다. ‘컬쳐쇼‘는 비영리 청년단체 청년문화포럼(회장:황희두)이 월 1회 주최하는 정기행사로써 청년활동가들이 기획부터 진행까지 직접 도맡아 하는 파일럿 형식의 문화행사이다.",
            image: "22.jpg",
            link: "http://www.e2news.com/news/articleView.html?idxno=92169", 
            date: "2016.04.11"
        });
        this.ArrayCreate({
            name: "청년문화포럼 제 4회 ‘컬쳐쇼’ 바둑경영특강 등으로 진행", 
            description: "지난 28일 제4회 컬쳐쇼가 강남역 신분당선 내 위치한 갤러리 카페 G아르체에서 진행되었다.(주최: 청년문화포럼 / 후원: 대한북레터협회, (주)넛지스토리, 매거진 열린사람들, 희여골, G아르체) ‘컬쳐쇼‘는 비영리 청년단체 청년문화포럼(회장:황희두)이 월 1회 주최하는 정기행사로써 청년활동가들이 기획부터 진행까지 직접 도맡아 하는 파일럿 형식의 문화행사이다.",
            image: "23.jpg",
            link: "http://www.e2news.com/news/articleView.html?idxno=93037", 
            date: "2016.05.09"
        });
        this.ArrayCreate({
            name: "청년을 이야기하다. 청년문화포럼의 <컬쳐쇼> 각 계에서 호평 쏟아져", 
            description: "제1회 컬쳐쇼(주최:청년문화포럼, 후원:대한북레터협회, 갤러리 카페 G아르체, (주)넛지스토리, 매거진 열린사람들, 희여골, 한국소상공인지원센터, KT올레 강남TV)가 지난 29일 강남역 신분당선 내 위치한 갤러리 카페 G아르체에서 녹화를 마쳤다. 컬쳐쇼는 총 2부로 진행되는 문화행사로써 1부는 명사 특강, 2부는 황국이의 북레터 토크쇼 ‘내 고민을 들어줘’ (진행 : 황희두, 국도형, 이승환) 로 이루어져 있다.",
            image: "24.jpg",
            link: "http://www.e2news.com/news/articleView.html?idxno=89750", 
            date: "2016.02.05"
        });
        this.setState({serch: ""});
    }

    render() {
        return <div id="PressComponent">
            <div className="container mb-4">
                <div className="form-check form-check-inline mt-4 mb-2">
                    <input className="form-check-input" type="radio" name="sort" id="sort-1" defaultChecked onClick={
                        () => {
                            this.setState({serchSort: 0});
                        }
                    }/>
                    <label className="form-check-label mr-3" htmlFor="sort-1">최신 순서</label>
                    <input className="form-check-input" type="radio" name="sort" id="sort-2" onClick={
                        () => {
                            this.setState({serchSort: 1});
                        }
                    }/>
                    <label className="form-check-label mr-3" htmlFor="sort-2">오래된 순서</label>
                    <input className="form-check-input" type="radio" name="sort" id="sort-3" onClick={
                        () => {
                            this.setState({serchSort: 2});
                        }
                    }/>
                    <label className="form-check-label mr-3" htmlFor="sort-3">제목 순서</label>
                    <input className="form-check-input" type="radio" name="sort" id="sort-4" onClick={
                        () => {
                            this.setState({serchSort: 3});
                        }
                    }/>
                    <label className="form-check-label mr-3" htmlFor="sort-4">내용 순서</label>
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <select className="input-group-text" onChange={
                            (event) => {
                                this.setState({serchMode: event.currentTarget.value});
                            }
                        }>
                            <option value="0">제목</option>
                            <option value="1">내용</option>
                            <option value="2">날짜</option>
                        </select>
                    </div>
                    <input type="text" className="form-control" value={this.state.serch} aria-label="keyword" aria-describedby="keyword" onChange={
                        (event) => {
                            this.setState({serch: event.currentTarget.value});
                        }
                    }/>
                </div>

                <div className="row no-gutters">
                    {this.ArrayPush()}
                </div>
            </div>
        </div>
    }
}

export default PressComponent;