import React from 'react';
import "./BlogComponent.css";
import "./SNSComponenet.css";
import "./PortalComponent.css";
import "./MediaComponent.css";

class MediaComponent extends React.Component
{
    render()
    {
        return <div className="top-padding">
        <div id="blog" className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">콘텐츠의 중심</span>
                        <span className="h-2">블로그</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/blog_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>콘텐츠의 중심인 블로그는 이슈 확산, 키워드, 노출 등 PR에 있어 대중들이 가장 친근하게 접할 수 있는 콘텐츠입니다.</div>
                        <div>브랜드 블로그, 최적화 블로그, 블로그 프레스를 통해 이슈가 안착할 수 있도록 콘텐츠 기획/스토리 라인을 타겟 선정 구성하고 있습니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">세대불문 콘텐츠 양성의 중심</span>
                        <span className="h-2">블로그</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/blog_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>처음 포털이 등장했을 때부터 블로그의 붐이 이르기까지 블로그에 대한 인기는 계속해서 높습니다.</div>
                        <div>현재 다양한 플랫폼의 등장으로 인해 블로그에 대한 인기는 다소 떨어졌지만</div>
                        <div>현재는 이슈 확산, 신뢰도를 결정짓는 필수적인 요소로 자리잡게 됐습니다.</div>
                    </div>

                    <div className="header text-center">
                        <span className="h-1">블로그 대표 구성</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-4">
                            <div className="circle">
                                브랜드 블로그
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                블로그 프레스
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                최적화 블로그
                            </div>
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            누가 쉽게 접할 수 있는 플랫폼으로 노출 ▲
                        </div>
                        <div className="border-box">
                            키워드를 통한 다방면 노출&후기
                        </div>
                        <div className="border-box">
                            실제 사람들의 후기와 생각을 반영하는 콘텐츠
                        </div>
                    </div>
                </div>
            </div>

            <div id="sns" className="container mt-4">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">높은 확산력과 파급력</span>
                        <span className="h-2">SNS</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="img-small" src="images/sns_component/1.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/sns_component/2.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 플랫폼 중 가장 높은 확산력과 파급력을 가지고 있는 SNS는 현재 페이스북과 인스타그램의 비중이 매우 높습니다.</div>
                        <div>20 대 10명 중 8명은 SNS을 이용하고 있으며 브랜드 페이지를 통해 확산력을 끌어올릴 수 있다는 것이 특징입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">콘텐츠 확산의 핵심</span>
                        <span className="h-2">페이스북</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-6">
                            <img className="w-100" src="images/sns_component/3.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="w-100" src="images/sns_component/4.png" alt="사진"/>
                        </div>
                    </div>


                    <div className="post">
                        <div>
                            페이스북은 콘텐츠 확산에 있어 매우 적합한 플랫폼입니다. 
                        </div>
                        <div>
                            좋아요, 공유를 통해 자신과 친구가 되어있는 이들에게 홍보하는 주체가 될 수 있습니다.
                        </div>
                        <div>
                            그렇기에 단순 1회 좋아요의 효과는 최소 100배 이상의 확산성을 가지고 있다고 볼 수 있습니다. 
                        </div>
                        <div>
                            또한, 페이스북의 맞춤 타겟팅 광고 시스템을 통해 특정 분야에 대상에게 집중적으로 전달할 수 있어 투자 대비 높은 효율을 나타낼 수 있는 가능성이 있다는 것이 특징입니다.
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="flex-box col-6">
                            <div className="circle">
                                브랜드 페이지
                            </div>
                        </div>
                        <div className="flex-box col-6">
                            <div className="circle">
                                페이스북<br/>광고
                            </div>
                        </div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">개인적인 공개 공간</span>
                        <span className="h-2">인스타그램</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/sns_component/5.png" alt="사진"/>

                    <div className="post">
                        <div>인스타그램은 공개적인 공간이지만 개인성이 철저히 보장되는 특성을 가지고 있습니다.</div>
                        <div>자신이 SNS의 중심이 되기 때문에 일상과 매우 밀접한 관계가 있어 현실과 매우 근접한 플랫폼입니다.</div>
                        <div>
                            좋아요를 누른다고해서 확산이 되는 것이 아니지만 자신이 관심 있는 분야에 대해 더보기 기능을 제공하여
                            맞춤 타겟이 가능하다는 것이 특징입니다.
                        </div>
                        <div>또한, 인스타그램에서만 활동하는 셀럽의 개념이 확립되어 있어 친근하게 타겟에 접근할 수 있는 특색이 있습니다.</div>
                    </div>

                    <div className="row mb-4">
                        <div className="flex-box col-4">
                            <div className="circle">
                                인스타 셀럽
                            </div>
                        </div>
                        <div className="flex-box col-4">
                            <div className="circle">
                                인스타 프레스
                            </div>
                        </div>
                        <div className="flex-box col-4">
                            <div className="circle">
                                타겟팅 광고
                            </div>
                        </div>
                    </div>

                    <div className="header mt-4">
                        <span className="h-1">소통과 확산의 중심 SNS</span>
                    </div>

                    <hr className="hr-bold"/>

                    <img className="w-100 mt-4" src="images/sns_component/6.png" alt="사진"/>

                    <div className="post">
                        <div>SNS는 소통과 확산에 매우 효과적인 플랫폼입니다.</div>
                        <div>과거 개인 SNS에서 활동하는 이들은 지금 시대에 인플루언서라 불리며 연예인 못지않은 인기를 가지고 있습니다.</div>
                        <div>
                            이를 통해 생성되는 브랜드 효과는 다른 SNS로 퍼져나가며 확산의 중심으로 작용할 수 있다는 것이 SNS의 가장 큰 장점이라 볼 수 있습니다.
                            맞춤 타겟이 가능하다는 것이 특징입니다.
                        </div>
                    </div>
                </div>
            </div>

            <div id="portal" className="container mt-4">
                <div className="container-small">
                <div className="header">
                        <span className="h-1">모든 정보를 연결하는 통로</span>
                        <span className="h-2">포털</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-12">
                            <img className="img-width" src="images/service_component/6.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/7.png" alt="사진"/>
                        </div>
                        <div className="col-6">
                            <img className="img-small" src="images/service_component/8.png" alt="사진"/>
                        </div>
                    </div>

                    <div className="post">
                        <div>현재 사용빈도가 가장 높은 플랫폼중 하나로 네이버, 구글, 다음을 꼽을 수 있습니다.</div>
                        <div>해당 포털은 이슈의 검색에 특화되어 있어 기업 PR진행 시 필수적으로 활용해야 하는 플랫폼입니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">검색의 최적화 포털</span>
                        <span className="h-2">포털</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/portal_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>일반인들이 가장 많이 활용하는 검색 플랫폼의 특징은 ‘신뢰성’입니다.</div>
                        <div>또한, 검색 플랫폼 특성상 적응된 디스플레이로 인해 플랫폼 이동이 드물어 반 지속적으로 사용한다는 특성을 가지고 있습니다.</div>
                        <div>
                            대형 플랫폼에서 활용하는 구성인 블로그, Q&A, 포스트, 이미지, 미디어, 웹문서를
                            활용한 다양한 구성을 통해 분야별 특색에 맞는 PR을 하나의 플랫폼을 통해 활용할 수 있다는 것이 특징입니다.
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            포털을 통한 다양한 연령층 다방면 노출
                        </div>
                        <div className="border-box">
                            검색시 기본이 되는 플랫폼으로 검색량 ▲
                        </div>
                        <div className="border-box">
                            정보 획득의 가장 기본이 되는 포털
                        </div>
                    </div>
                </div>
            </div>

            <div id="media" className="container mt-4">
            <div className="container-small">
                <div className="header">
                    <span className="h-1">현 시대 콘텐츠의 중심 핵심</span>
                    <span className="h-2">미디어</span>
                </div>

                <hr className="hr-bold mb-4"/>

                <img className="w-100" src="images/media_component/1.png" alt="사진"/>

                <div className="post">
                    현시대 핵심 콘텐츠 ‘미디어’는 전 연령대에 이르러 가장 인기 있는 콘텐츠 입니다.
                    최근 미디어의 상승률은 지속적으로 상승하고 있으며 그중 가장 인기 있는 동영상 중계 플랫폼
                    ‘유튜브’는 국내에서 플랫폼 이용 시간 1위를 달성했습니다. 또한 인터넷 이용률이 적은 50대 이상에서도
                    폭발적인 인기를 보여주고 있습니다. 플랫폼에서 일어나는 사건이 전국적으로 이슈가 될 수 있을 정도로
                    폭발성이 매우 높은 콘텐츠로 자리 잡고 있습니다.
                </div>

                <div className="header">
                    <span className="h-1">1인 방송의 변화</span>
                    <span className="h-2">크리에이터</span>
                </div>

                <hr className="hr-bold mb-4"/>

                <img className="w-100" src="images/media_component/2.png" alt="사진"/>

                <div className="post">
                    1인 방송의 인기가 높아짐에 따라 방송을 하는 사람(유튜버, 크리에이터, 스트리머)에 대한
                    대중들의 인식이 변화하기 시작했습니다. 정규 방송 심의 규정으로 인해 표현하지 못했던 부분들을
                    개인 방송을 통해 표현함으로써 시청자들과 친밀감과 소통을 통해 급격히 성장하게 됐습니다.
                    또한, 초등학생을 대상 장래희망 조사 1위에는 크리에이터가 나왔으며 지금은 단순히 1인 방송의 한계를
                    뛰어넘는 고품질 미디어 콘텐츠들이 창작되고 있습니다.
                </div>

                <div className="header">
                    <span className="h-1">방송 플랫폼</span>
                    <span className="h-2">유튜브, 아프리카tv, 트위치tv</span>
                </div>

                <hr className="hr-bold mb-4"/>
            </div>

            <div className="row">
                <div className="flex-dir col-4">
                    <img className="img-small" src="images/media_component/3.png" alt="사진"/>

                    <div className="sub-box">
                        <div>유튜브</div>
                        <ul>
                            <li>현재 가장 인기있는 플랫폼</li>
                            <li>전 연령층의 시청자를 보유함</li>
                            <li>영상 업로드 중심</li>
                        </ul>
                    </div>
                </div>
                <div className="flex-dir col-4">
                    <img className="img-small" src="images/media_component/4.png" alt="사진"/>

                    <div className="sub-box">
                        <div>트위치tv</div>
                        <ul>
                            <li>해외 본사를 두고 있는 플랫폼</li>
                            <li>라이브 중심 플랫폼</li>
                            <li>특정 분야 중심 콘텐츠가 다수</li>
                        </ul>
                    </div>
                </div>
                <div className="flex-dir col-4">
                    <img className="img-small" src="images/media_component/5.png" alt="사진"/>

                    <div className="sub-box">
                        <div>아프리카tv</div>
                        <ul>
                            <li>생방송 위주의 플랫폼</li>
                            <li>개인 방송 초창기를 이끈 주역</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container-small">
                <div className="margin-up">
                    <div className="border-box">
                        현재 가장 많이 활용하는 콘텐츠로 PR노출 효과 ▲
                    </div>
                    <div className="border-box">
                        단기간 성장 가능성이 가장 높은 콘텐츠
                    </div>
                    <div className="border-box">
                        생동감 있는 콘텐츠로 신뢰성 ▲
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
}

export default MediaComponent;