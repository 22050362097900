import React from 'react';
import "./ContentComponent.css";

class ContentComponent extends React.Component
{
    render()
    {
        return (
            <div id="content">
                <div className="banner-wrap">
                    <img className="banner" src="images/content_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">콘텐츠 제작</h1>
                    </div>
                </div>

                <div className="container">
                    <div className="container-small">
                        <div className="header">
                            <span className="h-1">PR 효과를 높이는 구성</span>
                            <span className="h-2">콘텐츠 제작</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/1.png" alt="사진"/>

                        <div className="post">
                            <div>넛지스토리의 콘텐츠 제작은 시대의 흐름에 따라 변화하고 있습니다.</div>
                            <div>과거의 텍스트부터 시작하여 영상까지 다양한 콘텐츠를 구성하고 있습니다.</div>
                            <div>단순 콘텐츠 제작이 아닌 각 콘텐츠별 가지고 있는 효과를 극대화 시켜 콘텐츠를 제작하고 있습니다.</div>
                            <div>또한, 변화하는 시대에 따라 트랜드에 맞게 변하여 PR효과를 높이는데 중심을 두고 있습니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">가이드 제작</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/2.png" alt="사진"/>

                        <div className="post">
                            <div>콘텐츠를 배포할 때 활용되는 가이드를 통해 효율적인 키워드, 브랜드 효과를 나타낼 수 있습니다.</div>
                            <div>형태(정보, 후기 공유 등등)에 맞는 형식으로 구성하여 다양한 분야에 사용이 가능합니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">영상 제작</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/3.png" alt="사진"/>

                        <div className="post">
                            <div>콘텐츠 중심 미디어를 중심으로 영상을 제작하는 콘텐츠입니다.</div>
                            <div>형태(정보, 후기 공유 등등)에 맞는 형식으로 구성하여 다양한 분야에 사용이 가능합니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">유튜브 채널 기획 및 운영 관리</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/4.png" alt="사진"/>

                        <div className="post">
                            <div>미디어 플랫폼 중 큰 인기를 끌고 있는 유튜브 채널 기획부터 운영 관리까지 진행하는 종합 서비스</div>
                            <div>현재 트렌드를 반영하여 효과적인 콘텐츠, 캐릭터 컨셉 기획을 통한 채널 성장</div>
                        </div>

                        <div className="header">
                            <span className="h-1">카드뉴스</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <div className="row">
                            <div className="col-6">
                                <img className="w-100" src="images/content_component/5.png" alt="사진"/>
                            </div>
                            <div className="col-6">
                                <img className="w-100" src="images/content_component/6.png" alt="사진"/>
                            </div>
                        </div>

                        <div className="post">
                            <div>카드뉴스는 이미지와 문구를 통해 소개하고자 하는 내용의 핵심을 명확하게 전달합니다.</div>
                            <div>SNS(페이스북, 인스타그램)에서 주로 활용되고 있으며 블로그, 카페 등 다방면에서 인기있는 콘텐츠입니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">크라우드 펀딩</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <div className="row">
                            <div className="col-6">
                                <img className="w-100" src="images/content_component/7.png" alt="사진"/>
                            </div>
                            <div className="col-6">
                                <img className="w-100" src="images/content_component/8.png" alt="사진"/>
                            </div>
                        </div>

                        <div className="post">
                            <div>크라우드 펀딩의 성공적인 목표를 달성하는 데 있어 콘텐츠 기획부터 펀딩 성공까지 진행되는 서비스입니다.</div>
                            <div>넛지스토리만의 크라우드 펀딩 비법과 사후 진행되는 PR을 통해 제품에 대한 특별함을 부여하는데 효과적입니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">웹툰 제작</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/9.png" alt="사진"/>

                        <div className="post">
                            <div>웹툰은 카드뉴스와 더불어 해당 내용을 알리는데 효과적으로 활용되고 있습니다.</div>
                            <div>넛지스토리만의 웹툰 작가를 통해 그림체, 내용, 콘티 등 다양한 분야를 직접 보고 선택할 수 있습니다.</div>
                        </div>

                        <div className="header">
                            <span className="h-1">전문 사진 촬영</span>
                        </div>

                        <hr className="hr-bold mb-4"/>

                        <img className="w-100" src="images/content_component/10.png" alt="사진"/>

                        <div className="post">
                            <div>PR에 있어 핵심적인 이미지를 전문 사진작가와 함께 제작하는 서비스입니다.</div>
                            <div>다양한 이미지별 전문 작가를 섭외하여 스튜디오 촬영, 야외 촬영 등 조율을 통해 진행됩니다.</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentComponent;