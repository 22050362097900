import React from 'react';
import './PolicyComponent.css';

class PolicyComponent extends React.Component
{
    render()
    {
        return (
            <div id="policy">
                <div className="banner-wrap">
                    <img className="banner" src="images/policy_component/banner.jpg" alt="배너"/>
                    <div className="banner-content">
                        <h1 className="mb-4">경영방침</h1>
                    </div>
                </div>

                <div className="container">
                    <img className="main-image" src="images/policy_component/0.png" alt="policy"/>
                    <ul className="text">
                        <li>◆ 강요에 의한 기부나 비자발적인 봉사가 아닌 ‘나눔’</li>
                        <li>◆ 넘치는 부분이나 할애할 수 있는 부분을 더 필요한 이에게 ‘배려’</li>
                        <li>◆ 불행해 하는 누군가를 위해 따뜻하게 안아주는 ‘공유’</li>
                        <li>◆ 나눔, 양보, 공유를 통한 ‘사회재분배’</li>
                        <li>◆ 기업의 ‘도덕성&신뢰성’</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default PolicyComponent;