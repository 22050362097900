import React from 'react';
import "./FreepassionalComponent.css";

class FreepassionalComponent extends React.Component
{
    render()
    {
        return <div id="freepassional">
            <div className="banner-wrap">
                <img className="banner" src="images/freepassional_component/banner.jpg" alt="배너"/>
                <div className="banner-content">
                    <h1 className="mb-4">프리패셔널</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <div className="main-logo">
                            <img src="images/freepassional_component/logo.png" alt="로고"/>
                            <div className="small-border">프리패셔널</div>
                        </div>
                    </div>

                    <div className="col-lg-10 col-12">
                        <div className="header">
                            <span className="h-1">프리랜서를 전문가로 만드는 실무 과정</span>
                            <span className="h-2">프리패셔널</span>
                        </div>

                        <hr className="hr-bold"/>

                        <div className="introduce">
                            <div>
                                프리패셔널 과정은 프리랜서를 전문가로 만드는 교육 과정으로 프리랜서로 활동하고자 하는 이들에게
                                전문 교육을 통해 전문성을 부여하고 해당 분야에 전문가로서 실무를 진행할 수 있도록 하는 교육 플랫폼입니다.
                            </div>
                            <div>
                                유통, 마케팅, 세무, 법률, 디자인, 패션, 뷰티, 홈페이지, 홈쇼핑 등 다양한 분야 검증된 현직 전문가의
                                실무 교육을 프리패셔널에서 직접 선택하여 교육이 진행됩니다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-small mb-4">
                    <div className="header">
                        <span className="h-1">프리패셔널 전문가 검증 과정</span>
                        <span className="h-2">프리패셔널 전문가</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/freepassional_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>프리패셔널은 수준의 교육수준을 유지하기 위해 서류 검증, 오프라인 검증, 프리패셔널 교육을 진행하고 있습니다.</div>
                        <div>해당 과정은 전문가로 프리패셔널에 등록하기 위해 필수적으로 진행되는 과정으로 예외된 전문가는 없습니다.</div>
                        <div>서류 검증은 전문가의 경력/실무를 중심으로 확인하고 있으며 해당 경력이 검증되지 않는 경우 인정될 수 없습니다.</div>
                    </div>

                    <div className="mb-4">
                        <div className="border-box"><span>프리패셔널 전문가 신청</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>전문가 서류 검증(1차)</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>오프라인 미팅(2차)</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>프리패셔널 전문가 교육</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>전문가 등록</span></div>
                    </div>

                    <div className="header">
                        <span className="h-1">전문가를 직접 선택</span>
                        <span className="h-2">프리패셔널 과정</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/freepassional_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>프리패셔널을 이용하시는 분들은 인증된 전문가를 직접 선택하여 교육을 이수할 수 있습니다.</div>
                        <div>또한, 해당 절차를 최소한으로 줄여 빠르고 간편하게 전문가와 연결할 수 있다는 것이 특징입니다.</div>
                    </div>

                    <div>
                        <div className="border-box text-sm"><span>프리패셔널 홈페이지 회원가입</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>원하는 과정 및 교육 선택</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>전문가 일정 조율</span></div>
                        <img src="images/freepassional_component/arrow.png" alt="사진"/>
                        <div className="border-box"><span>교육 진행</span></div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default FreepassionalComponent;