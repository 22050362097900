import React from 'react';
import "./BlogComponent.css";

class BlogComponent extends React.Component
{
    render()
    {
        return <div id="blog" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">콘텐츠의 중심</span>
                        <span className="h-2">블로그</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/blog_component/1.png" alt="사진"/>

                    <div className="post">
                        <div>콘텐츠의 중심인 블로그는 이슈 확산, 키워드, 노출 등 PR에 있어 대중들이 가장 친근하게 접할 수 있는 콘텐츠입니다.</div>
                        <div>브랜드 블로그, 최적화 블로그, 블로그 프레스를 통해 이슈가 안착할 수 있도록 콘텐츠 기획/스토리 라인을 타겟 선정 구성하고 있습니다.</div>
                    </div>

                    <div className="header">
                        <span className="h-1">세대불문 콘텐츠 양성의 중심</span>
                        <span className="h-2">블로그</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100" src="images/blog_component/2.png" alt="사진"/>

                    <div className="post">
                        <div>처음 포털이 등장했을 때부터 블로그의 붐이 이르기까지 블로그에 대한 인기는 계속해서 높습니다.</div>
                        <div>현재 다양한 플랫폼의 등장으로 인해 블로그에 대한 인기는 다소 떨어졌지만</div>
                        <div>현재는 이슈 확산, 신뢰도를 결정짓는 필수적인 요소로 자리잡게 됐습니다.</div>
                    </div>

                    <div className="header text-center">
                        <span className="h-1">블로그 대표 구성</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row">
                        <div className="col-4">
                            <div className="circle">
                                브랜드 블로그
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                블로그 프레스
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle">
                                최적화 블로그
                            </div>
                        </div>
                    </div>

                    <div className="margin-up">
                        <div className="border-box">
                            누가 쉽게 접할 수 있는 플랫폼으로 노출 ▲
                        </div>
                        <div className="border-box">
                            키워드를 통한 다방면 노출&후기
                        </div>
                        <div className="border-box">
                            실제 사람들의 후기와 생각을 반영하는 콘텐츠
                        </div>
                    </div>
                </div>
            </div>

            <div className="debug-line"/>

            <div style={{marginBottom:"2%"}} className="padding">
                <img className="img_block" src="images/blog_component/0.jpg" alt="블로그"/>
            </div>
        </div>;
    }
}

export default BlogComponent;