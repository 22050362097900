import React from 'react';
import {NavLink} from 'react-router-dom';
import "./Footer.css";

class Footer extends React.Component
{
    render()
    {
        return (
        <div className="footer">
            <div className="footer_wrap">
                <ul className="footer_ul">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-4">
                                <li>
                                    <ul>
                                        <NavLink to="/"><li>넛지스토리</li></NavLink>
                                    </ul>
                                </li>
                            </div>
                    
                            <div className="col-md-2 col-4">
                                <li>
                                    <ul>
                                        {/* <NavLink to="/intro"><li>대표 소개</li></NavLink> */}
                                        <NavLink to="/info"><li>회사 소개</li></NavLink>
                                        <NavLink to="/policy"><li>경영방침</li></NavLink>
                                        <NavLink to="/organization"><li>조직도</li></NavLink>
                                        <NavLink to="/how"><li>PR이란?</li></NavLink>
                                    </ul>
                                </li>
                            </div>
                    
                            <div className="col-md-2 col-4">
                                <li>
                                    <ul>
                                        {/* <NavLink to="/youtube"><li>열크사</li></NavLink> */}
                                        <NavLink to="/opener"><li>열린사람들</li></NavLink>
                                        <NavLink to="/peosnal"><li>개인브랜드</li></NavLink>
                                        <NavLink to="/service"><li>PR서비스</li></NavLink>
                                        <NavLink to="/freepassional"><li>프리패셔널</li></NavLink>
                                        <NavLink to="/oedm"><li>OEM/ODM</li></NavLink>
                                        <NavLink to="/content"><li>콘텐츠 제작</li></NavLink>
                                    </ul>
                                </li>
                            </div>

                            <div className="col-md-2 col-4 mt-lg-0 mt-3">
                                <li>
                                    <ul>
                                        <NavLink to="/configuration"><li>PR구성</li></NavLink>
                                        {/* <NavLink to="/package"><li>패키지(Package)</li></NavLink> */}
                                        <NavLink to="/report"><li>언론 플레이</li></NavLink>
                                        {/* <NavLink to="/blog"><li>블로그(Blog)</li></NavLink> */}
                                        {/* <NavLink to="/sns"><li>SNS</li></NavLink> */}
                                        {/* <NavLink to="/portal"><li>포털(Portal)</li></NavLink> */}
                                        <NavLink to="/media"><li>여론형성</li></NavLink>
                                        <NavLink to="/brand"><li>브랜드기획&제작</li></NavLink>
                                        {/* <NavLink to="/play"><li>언론플레이</li></NavLink> */}
                                    </ul>
                                </li>
                            </div>

                            <div className="col-md-2 col-4 mt-lg-0 mt-3">
                                <li>
                                    <ul>
                                        <NavLink to="/campaign"><li>사회공헌</li></NavLink>
                                    </ul>
                                </li>
                            </div>

                            <div className="col-md-2 col-4 mt-lg-0 mt-3">
                                <li>
                                    <ul>
                                        <NavLink to="/pr"><li>PR문의</li></NavLink>
                                        <NavLink to="/question"><li>제휴문의</li></NavLink>
                                    </ul>
                                </li>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        );
    }
}

export default Footer;