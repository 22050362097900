import React from 'react';
import "./HowPRComponent.css";

class HowPRComponent extends React.Component
{
    render()
    {
        return <div id="how-pr" className="top-padding">
            <div className="container">
                <div className="container-small">
                    <div className="header">
                        <span className="h-1">PR이란?</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <img className="w-100 mb-2" src="images/how_component/0.png" alt="사진"/>
                    <div>“공중과의 관계를 자신에게 유리하게 이끌어 나가기 위해</div>
                    <div>수행하는 일체의 커뮤니케이션 활동 또는 그러한 활동의 상태나 기술”</div>

                    <div className="main-stream mt-3">
                        <div>불특정 다수의 일반 대중을 대상으로</div>
                        <div>이미지의 제고나 제품 홍보 등을 주목적으로 함</div>
                    </div>

                    <div className="header">
                        <span className="h-1">PR 대표 기능</span>
                    </div>

                    <hr className="hr-bold mb-4"/>

                    <div className="row mb-3">
                        <div className="col-3">
                            <div className="border-header">평판 관리</div>
                        </div>
                        <div className="col-9">
                            <div>기업이 다양한 공중들에게 인신되는</div>
                            <div>이미지를 관리하는 일반적 의미의 기업PR 활동</div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-3">
                            <div className="border-header">쟁점 관리</div>
                        </div>
                        <div className="col-9">
                            <div>기업-공중간의 관계에 영향을 미치는 공공정책 이슈들을</div>
                            <div>예상, 규명, 평가, 반응하는 사전 반응적 과정</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="border-header">위기 관리</div>
                        </div>
                        <div className="col-9">
                            <div>기업이 바람직하지 않은 결과를 가져오는 것을 감지하고</div>
                            <div>이에 대해 대비책을 마련하여 위기를 억제한다.</div>
                            <div>위기시 이를 극복하고 최소의 비용으로 복귀하는 과정</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default HowPRComponent;