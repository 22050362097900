import React from 'react';
import {CaretUpFill, CaretDownFill} from 'react-bootstrap-icons';
import $ from 'jquery';
import './Scrolling.css';

class Scrolling extends React.Component
{
  scrollTop = () => {
    const check = $("#scroll-button-top.active");
    if (check !== null)
    {
      const html = $("html, body");
      html.animate({scrollTop: 0}, 600);
    }
  }

  scrollBottom = () => {
    const check = $("#scroll-button-bottom.active");
    if (check !== null)
    {
      const html = $("html, body");
      html.animate({scrollTop: html.height()}, 1000);
    }
  }

  viewCheck = () => {
    $("#scroll-button-bottom").addClass("active");

    $(window).scroll(() => {
      if($(window).scrollTop() <= 0) {
        $("#scroll-button-top").removeClass("active");
      } else {
        $("#scroll-button-top").addClass("active");
      }

      if($(window).scrollTop() + $(window).height() === $(document).height()) {
        $("#scroll-button-bottom").removeClass("active");
      } else {
        $("#scroll-button-bottom").addClass("active");
      }
    });
  }

  componentDidMount() {
    this.viewCheck();
  }

  render() {
    return <div id="scrolling">
      <div id="scroll-button-top" className="button-wrap button-top" onClick={this.scrollTop}>
        <CaretUpFill className="img"/>
      </div>
      <div id="scroll-button-bottom" className="button-wrap button-bottom" onClick={this.scrollBottom}>
        <CaretDownFill className="img"/>
      </div>
    </div>;
  }
}

export default Scrolling;